<template>
  <div>
    <headers></headers>
    <div>
      <slot />
    </div>
    <footers></footers>
  </div>
</template>

<script>
import headers from './components/headers/index.vue';
import footers from './components/footers/index.vue';

export default {
  components: {
    headers,
    footers,
  },
};
</script>
