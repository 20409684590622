<template>
  <div>
    <help-center-header @search="search" v-model="subject"></help-center-header>
    <div>
      <div
        class="w-1200px m-auto my-30px px-18px py-12px bg-[#6b62b908] flex items-center justify-between rounded-2px select-none"
      >
        <div
          class="cursor-pointer font-bold rounded-2px py-4px px-12px h-40px flex items-center"
          @click="switchTab('latestUpdates')"
          :class="{
            'bg-[#37108b] text-white': type == 'latestUpdates',
          }"
        >
          Latest Updates
        </div>
        <div
          class="cursor-pointer font-bold rounded-2px py-4px px-12px h-40px flex items-center"
          @click="switchTab('iYooweFeatures')"
          :class="{
            'bg-[#37108b] text-white': type == 'iYooweFeatures',
          }"
        >
          iYoowe Features
        </div>
        <div
          class="cursor-pointer font-bold rounded-2px py-4px px-12px h-40px flex items-center"
          @click="switchTab('authorizeStores')"
          :class="{
            'bg-[#37108b] text-white': type == 'authorizeStores',
          }"
        >
          Authorize Stores
        </div>
        <div
          class="cursor-pointer font-bold rounded-2px py-4px px-12px h-40px flex items-center"
          @click="switchTab('myAccount')"
          :class="{
            'bg-[#37108b] text-white': type == 'myAccount',
          }"
        >
          My Account
        </div>
        <div
          class="cursor-pointer font-bold rounded-2px py-4px px-12px h-40px flex items-center"
          @click="switchTab('productsAndOders')"
          :class="{
            'bg-[#37108b] text-white': type == 'productsAndOders',
          }"
        >
          Products & Oders
        </div>
        <div
          class="cursor-pointer font-bold rounded-2px py-4px px-12px h-40px flex items-center"
          @click="switchTab('shippingAndTracking')"
          :class="{ 'bg-[#37108b] text-white': type == 'shippingAndTracking' }"
        >
          Shipping & Tracking
        </div>
        <div
          class="cursor-pointer font-bold rounded-2px py-4px px-12px h-40px flex items-center"
          @click="switchTab('paymentAndTaxes')"
          :class="{ 'bg-[#37108b] text-white': type == 'paymentAndTaxes' }"
        >
          Payment & Taxes
        </div>
        <div
          class="cursor-pointer font-bold rounded-2px py-4px px-12px h-40px flex items-center"
          @click="switchTab('aftersales')"
          :class="{ 'bg-[#37108b] text-white': type == 'aftersales' }"
        >
          Aftersales
        </div>
      </div>

      <div
        class="w-1200px flex flex-col justify-center m-auto"
        v-loading="isListLoading"
      >
        <el-card
          class="mb-20px"
          v-for="item in data.rows"
          :key="item.article_id"
        >
          <div class="w-full p-20px">
            <div
              class="text-2xl w-max cursor-pointer hover:underline"
              @click="toDetail(item.article_id)"
            >
              {{ item.subject }}
            </div>
            <div class="text-normal mt-16px font-light">
              <p>{{ item.description }}</p>
            </div>
          </div>
        </el-card>
        <div
          v-if="data.rows.length == 0"
          class="text-center text-xl text-[#979797]"
        >
          No data was detected
        </div>
      </div>
      <div class="flex justify-end w-1200px m-auto pb-24px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[5, 10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="data.count"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import HelpCenterHeader from './components/help-center-header/index.vue';

export default {
  name: 'helpMain',
  components: { HelpCenterHeader },
  props: {
    defaultQuery: String,
    defaultType: [String],
  },
  created() {
    document.documentElement.scrollTop = 0;
    this.list(this.defaultQuery);
  },
  data() {
    return {
      isListLoading: false,
      type: this.defaultType || 'latestUpdates',
      page: 1,
      pageSize: 5,
      subject: this.defaultQuery || null,
      data: {
        rows: [],
      },
    };
  },
  methods: {
    search() {
      this.switchTab('latestUpdates', true);
    },
    getType() {
      switch (this.type) {
        case 'latestUpdates':
          return null;
        case 'iYooweFeatures':
          return 1;
        case 'authorizeStores':
          return 2;
        case 'myAccount':
          return 3;
        case 'productsAndOders':
          return 4;
        case 'shippingAndTracking':
          return 5;
        case 'paymentAndTaxes':
          return 6;
        case 'aftersales':
          return 7;
        default:
          return null;
      }
    },
    list() {
      this.isListLoading = true;
      let params = {
        page: this.page,
        page_size: this.pageSize,
        subject: this.subject || undefined,
        type: this.getType(),
      };
      this.$request
        .get('article/list', params)
        .then((res) => {
          this.data = res.data;
        })
        .catch((e) => {
          this.$message.error(e.message);
        })
        .finally(() => {
          this.isListLoading = false;
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.list();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.list();
    },
    switchTab(type, force = false) {
      if (this.type == type && !force) {
        return;
      }
      this.type = type;
      this.page = 1;
      this.pageSize = 5;
      this.$router
        .replace({
          name: 'helpCenterWithType',
          params: {
            type,
          },
        })
        .catch((_) => _);
      this.list();
    },
    toDetail(id) {
      this.$router
        .push({
          name: 'helpCenterDetail',
          params: {
            id,
          },
        })
        .catch((_) => _);
    },
  },
};
</script>
