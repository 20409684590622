import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '@/views/Index';
import Sourcing from '@/views/sourcing';
import Wholesale from '@/views/wholesale';
import pod from '@/views/pod';
import About from '@/views/About';
import blog from '@/views/blog';
import blogDetail from '@/views/blog/detail';
import helpCenterDetail from '@/views/helpCenter/detail';
import helpMain from '@/views/helpCenter';
import shipping from '@/views/shipping';
import requestQuote from '@/views/shipping/request-quote.vue';

import privacy from '@/views/privacy';
import terms from '@/views/terms';
import refund from '@/views/refund';
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/',
    component: Index,
  },
  {
    path: '/index',
    redirect: '/',
  },
  {
    path: '/sourcing',
    component: Sourcing,
  },
  {
    path: '/souring',
    redirect: '/sourcing',
  },
  {
    path: '/wholesale',
    component: Wholesale,
  },
  {
    path: '/pod',
    component: pod,
  },
  {
    path: '/aboutUs',
    component: About,
  },
  {
    path: '/blog',
    name: 'blog',
    component: blog,
  },
  {
    path: '/blog/:type',
    name: 'blogWithType',
    component: blog,
    props: (route) => {
      return {
        defaultType: route.params.type,
      };
    },
  },
  {
    path: '/blog/detail/:id',
    name: 'blogDetail',
    component: blogDetail,
  },
  {
    path: '/helpCenter',
    name: 'helpCenter',
    component: helpMain,
    props: (route) => {
      return {
        defaultQuery: route.params.query,
      };
    },
  },
  {
    path: '/helpCenter/:type',
    name: 'helpCenterWithType',
    component: helpMain,
    props: (route) => {
      return {
        defaultType: route.params.type,
        defaultQuery: route.params.query,
      };
    },
  },
  {
    path: '/helpCenter/detail/:id',
    name: 'helpCenterDetail',
    component: helpCenterDetail,
  },
  {
    path: '/privacy',
    component: privacy,
  },
  {
    path: '/terms',
    component: terms,
  },
  {
    path: '/refund',
    component: refund,
  },
  {
    path: '/shipping',
    component: shipping,
  },
  {
    path: '/shipping/request-quote',
    component: requestQuote,
  },
];

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({
    y: 0,
  }),
  routes,
});

router.beforeEach((to, from, next) => {
  let isChanged = false;
  for (let name in from.query) {
    if (!to.query[name] || to.query[name] != from.query[name]) {
      to.query[name] = from.query[name];
      isChanged = true;
    }
  }
  if (isChanged) {
    next(to);
  } else {
    next();
  }
});

export default router;
