import '../../assets/scss/element-variables.scss';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';

locale.use(lang);

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  Carousel,
  CarouselItem,
  Checkbox,
  CheckboxGroup,
  Col,
  Divider,
  Form,
  FormItem,
  Input,
  Loading,
  Message,
  Option,
  Pagination,
  Popover,
  Row,
  Select,
  Table,
  TableColumn,
  Tag,
} from 'element-ui';

export default {
  install(Vue) {
    Vue.component(Breadcrumb.name, Breadcrumb);
    Vue.component(BreadcrumbItem.name, BreadcrumbItem);
    Vue.component(Button.name, Button);
    Vue.component(Card.name, Card);
    Vue.component(Carousel.name, Carousel);
    Vue.component(CarouselItem.name, CarouselItem);
    Vue.component(Checkbox.name, Checkbox);
    Vue.component(CheckboxGroup.name, CheckboxGroup);
    Vue.component(Col.name, Col);
    Vue.component(Divider.name, Divider);
    Vue.component(Form.name, Form);
    Vue.component(FormItem.name, FormItem);
    Vue.component(Input.name, Input);
    Vue.component(Option.name, Option);
    Vue.component(Pagination.name, Pagination);
    Vue.component(Popover.name, Popover);
    Vue.component(Row.name, Row);
    Vue.component(Select.name, Select);
    Vue.component(Table.name, Table);
    Vue.component(TableColumn.name, TableColumn);
    Vue.component(Tag.name, Tag);
    Vue.use(Loading.directive);
    Vue.prototype.$message = Message;
  },
};
