<template>
  <div class="boder">
    <div class="background_image">
      <img src="/img/2.png" alt="" />
    </div>
    <div class="advantage libre-franklin">
      <p>Buy best way by fast way</p>
      <img src="../../assets/img/image/weibiaoti.png" alt="" />
    </div>
    <div class="detail_iYoowe">
      iYoowe gears up your online business by providing a one-stop solution for
      global dropshippers and retailers, equipping you with the integrated
      system that makes sourcing, fulfilling and shipping your products a breeze
    </div>
    <div class="get_started" @click="gotoApp('iyoowe-selected')">
      <strong>GET STARTED</strong>
    </div>
    <div class="background_video">
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/ldUH532JL2w?si=lEdhkg27nChbgxdm"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
    <div class="iyoowe">
      <div class="dropshipp">
        <div class="one-click">
          <img src="../../assets/img/image/TOP.png" alt="" />
          <div class="wordflow">
            <p>
              Creat a Better
              <span style="font-size: 48px">Dropshipping </span>
              <span style="color: #00a7b5; font-size: 53px">Workflow</span>
            </p>
            <p>
              With the one-click syncing function we offer, the procedure of
              sourcing and listing can be completed with a simple click,
              dropshipping has never been so easy.
            </p>
            <div
              @click="gotoApp('register', 'clickRegisterButton')"
              class="flex items-center"
            >
              <div class="text-white text-20px">Experience it now</div>
              <icon icon="charm:arrow-up-right" width="24" color="white"></icon>
            </div>
          </div>
        </div>
        <div class="iyoowe-video h-420px overflow-hidden">
          <video width="485" height="496" loop="loop" autoplay="autoplay">
            <source src="/video/1.mp4" type="video/mp4" />
          </video>
          <div class="h-1px -mt-1px"></div>
        </div>
      </div>
    </div>
    <div class="iYoowe_china">
      <strong>iYoowe - Your Way to China Supply Chain</strong>
    </div>
    <div class="advantage_detail">
      <div class="quote_left">
        <img src="../../assets/img/image/“.png" alt="" />
        <img src="../../assets/img/image/“.png" alt="" />
      </div>
      <div class="advantage_content">
        <div class="advantage_img">
          <div class="quality"><strong>Quality Supply</strong></div>
          <div class="quality_img">
            <img src="../../assets/img/image/bianzu13.png" alt="" />
          </div>
          <div class="quality_content">
            Every single product on iYoowe is strictly selected<br />
            from authentic manufacturers. We offer
            <strong>100,000+<br />products</strong>
            for you to choose from. Trending products<br />
            with good quality made easy.
          </div>
          <div class="cruise">
            <img src="/img/index-1.png" alt="" />
          </div>
          <div
            class="go_seleted flex items-center"
            @click="gotoApp('iyoowe-selected')"
          >
            <div>Go to iYoowe Seleted</div>
            <icon
              class="ml-8px"
              icon="charm:arrow-up-right"
              width="24"
              color="white"
            ></icon>
          </div>
          <div class="source_img">
            <img src="/img/index-2.png" alt="" />
          </div>
          <div class="source_title">
            <strong>Source Everything</strong>
          </div>
          <div class="source_right_img">
            <img src="../../assets/img/image/bianzu13.png" alt="" />
          </div>
          <div class="source_content">
            Free sourcing service, we help you track down
            <strong>anything and<br />everything</strong>
            you want in 1-3 business days. With the most<br />
            competitive quotation, every product will get strictly inspected<br />
            before shipping. Fast, easy, and affordable to door delivery,<br />
            iYoowe help you source and ship effortlessly anytime anywhere.
          </div>
          <div
            class="start_sourcing flex items-center"
            @click="$router.push({ path: '/souring' }).catch((_) => _)"
          >
            <div>Start Sourcing</div>
            <icon
              class="ml-8px"
              icon="charm:arrow-up-right"
              width="24"
              color="white"
            ></icon>
          </div>
          <div class="fast_shipping">
            <strong>Fast Shipping</strong>
          </div>
          <div class="shipping_right_img">
            <img src="../../assets/img/image/bianzu13.png" alt="" />
          </div>
          <div class="shipping_content">
            iYoowe is a reliable partner that can help you with all of your
            logistics needs by the ability of shipping globally. It only takes
            about 6-11 days to deliver to Northern America and most countries in
            Europe. Besides, we offer a money-back guarantee for the delivery
            issues you might encounter. Choose iYoowe, make the shipping time
            your absolute competitive advantage.
          </div>
          <div class="shipping-learn-more" @click="goShipping">Learn More</div>
          <div class="fast_shipping_img">
            <img src="/img/index-3.png" alt="" />
          </div>
        </div>
        <div class="content_deep">
          <div class="last_img">
            <img src="/img/index-4.png" alt="" />
          </div>
          <div class="last_right">
            <div class="wholesale_program">
              <p class="wholesale"><strong>Wholesale Program</strong></p>
              <img src="../../assets/img/image/bianzu13.png" alt="" />
            </div>
            <div class="wholesale_contet">
              We offer wholesale solutions to everything that you can <br />
              find on iYoowe or through iYoowe. With a reliable<br />
              network that reaches the best suppliers in China, we<br />
              provide <strong>detailed and competitive quotes</strong> in 1-3<br />
              business days. iYoowe is dedicated to helping <br />
              customers through effective communication, quality<br />
              inspection and fast shipping.
            </div>
            <div
              class="learn_more"
              @click="gotoApp('register', 'clickRegisterButton')"
            >
              Learn More
            </div>
          </div>
        </div>
      </div>
      <div class="quote_right">
        <img src="../../assets/img/image/“(1).png" alt="" />
        <img src="../../assets/img/image/“(1).png" alt="" />
      </div>
    </div>
    <div class="empowers mt-150px">How iYoowe empowers</div>
    <div class="empowers">your business?</div>
    <div class="iYoowe_business">
      <div class="business_list">
        <div>
          <div></div>
          <p>Fulfillment Service</p>
        </div>
        <div>
          <div></div>
          <p>Custom Packaging</p>
        </div>
        <div>
          <div></div>
          <p>Private Inventory</p>
        </div>
        <div>
          <div></div>
          <p>Wholesale Service</p>
        </div>
        <div>
          <div></div>
          <p>Simplify Order Management</p>
        </div>
        <div>
          <div></div>
          <p>Fast Processing & Delivery Time</p>
        </div>
        <div>
          <div></div>
          <p>Real-Time Order Tracking</p>
        </div>
        <div>
          <div></div>
          <p>Dedicated Customers Service</p>
        </div>
      </div>
    </div>
    <div class="dropshipping">
      <strong>Start dropshipping on iYoowe with 5 steps</strong>
    </div>
    <div class="shipping_steps">
      <div>
        <div><strong>Step1</strong></div>
        <div></div>
        <div>Authorize Your Store</div>
      </div>
      <div class="pt-24px">
        <icon icon="oi:arrow-right" color="#979797" width="50"></icon>
      </div>
      <div>
        <div><strong>Step2</strong></div>
        <div></div>
        <div>Find Products</div>
      </div>
      <div class="pt-24px">
        <icon icon="oi:arrow-right" color="#979797" width="50"></icon>
      </div>
      <div>
        <div><strong>Step3</strong></div>
        <div></div>
        <div>Sync & Place Orders</div>
      </div>
      <div class="pt-24px">
        <icon icon="oi:arrow-right" color="#979797" width="50"></icon>
      </div>
      <div>
        <div><strong>Step4</strong></div>
        <div></div>
        <div>Fulfill And Deliver</div>
      </div>
      <div class="pt-24px">
        <icon icon="oi:arrow-right" color="#979797" width="50"></icon>
      </div>
      <div>
        <div><strong>Step5</strong></div>
        <div></div>
        <div>Get Your Profit</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";
import { gotoApp } from "../../utils/location";

export default {
  name: "index",
  components: {
    Icon,
  },
  mounted() {
    var local1 = document.querySelector(".iyoowe-video>video"); //获取，函数执行完成后local内存释放
    local1.muted = true; // 关闭声音，如果为false,视频无法自动播放
    if (local1.paused) {
      //判断是否处于暂停状态
      local1.play(); //开启播放
    }
  },
  data() {
    return {
      dataLayer: null,
    };
  },
  methods: {
    gotoApp,
    goShipping() {
      this.$router.push("/shipping").catch((_) => _);
    },
  },
};
</script>

<style lang="scss" scoped>
.boder {
  width: 100%;
  height: 100%;
  .background_image {
    position: relative;
    width: 100%;
    height: 823px;
    z-index: -1;
    background-color: #f8f8f8;
    img {
      width: 100%;
      height: 823px;
    }
  }
  @media only screen and (max-width: 1920px) {
    .background_image {
      background-size: 1920px 823px;
    }
  }
  .advantage {
    position: relative;
    width: 582px;
    height: 196px;
    margin: -723px auto 0;
    color: #fff;
    font-size: 70px;
    font-weight: 550;
    line-height: 96px;
    text-align: center;
    z-index: -1;
    & > img {
      position: absolute;
      left: 125px;
      bottom: -10px;
      width: 187px;
      height: 38px;
      z-index: -1;
    }
  }
  .detail_iYoowe {
    width: 757px;
    height: 118px;
    margin: 41px auto 0;
    color: #fff;
    font-size: 18px;
    text-align: center;
    line-height: 39px;
  }

  .get_started {
    width: 237px;
    height: 47px;
    margin: 43px auto 0;
    background: #6b62b9;
    border-radius: 34px;
    line-height: 47px;
    text-align: center;
    font-size: 21px;
    color: #fff;
    z-index: 99;
    cursor: pointer;
  }
  .background_video {
    width: 814px;
    height: 457px;
    margin: 78px auto 0;
    & > iframe {
      width: 100%;
      height: 100%;
    }
  }
  .iyoowe {
    width: 100%;
    height: 922px;
    margin-top: -257px;
    padding-top: 368px;
    box-sizing: border-box;
    background: #f8f8f8;
    .dropshipp {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      height: 510px;
      margin: 0 auto;
      .one-click {
        .wordflow {
          margin-left: 77px;
          margin-top: -65px;
          & > p:nth-of-type(1) {
            width: 638px;
            height: 169px;
            font-size: 60px;
            color: #000000;
            line-height: 70px;
            // letter-spacing: -3px;
          }
          & > p:nth-of-type(2) {
            width: 511px;
            height: 151px;
            margin-top: 6px;
            font-size: 24px;
            font-family: HarmonyOS_Sans_SC_Light;
            color: #404040;
            line-height: 39px;
          }
          & > div:nth-of-type(1) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 267px;
            height: 52px;
            margin-top: 33px;
            padding: 0 38px;
            background: #00a7b5;
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.69);
            border-radius: 39px;
            box-sizing: border-box;
            cursor: pointer;
            &:hover {
              margin-top: 28px;
              transition: all 0.5s ease;
            }
            p {
              font-size: 18px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  .iYoowe_china {
    width: 705px;
    height: 132px;
    margin-top: 82px;
    margin-left: 50%;
    transform: translate(-50%);
    font-size: 48px;
    font-weight: normal;
    color: #404040;
    line-height: 66px;
    text-align: center;
  }

  .advantage_detail {
    position: relative;
    display: flex;
    align-items: center;
    width: 1200px;
    margin-top: 36px;
    margin-left: 50%;
    transform: translate(-50%);
    .quote_left {
      // position: relative;
      flex: 1;
    }
    .quote_right {
      // position: relative;
      flex: 1;
    }
  }

  .quote_left > img:nth-child(1) {
    position: absolute;
    top: 72px;
    left: 0;
  }

  .quote_left > img:nth-child(2) {
    position: absolute;
    top: 1216px;
  }

  .quote_right img:nth-child(1) {
    position: absolute;
    top: 678px;
    right: 0;
  }

  .quote_right img:nth-child(2) {
    position: absolute;
    top: 1872px;
    right: 0;
  }

  .advantage_content {
    width: 962px;
  }

  .advantage_img {
    position: relative;
    top: 0;
    left: 128px;
    width: 844px;
    height: 1983px;
    background-image: url(../../assets/img/image/60f835b74a8c5743d88a28b8_lines-home.png);
    background-size: 100% 100%;
    /* background-color: pink; */
    z-index: 8;
  }

  .advantage_img .quality {
    position: absolute;
    top: 140px;
    left: -122px;
    width: 302px;
    height: 48px;
    font-size: 32px;
    font-weight: normal;
    color: #373737;
    line-height: 48px;
  }

  .advantage_img .quality_img {
    position: absolute;
    top: 164px;
    right: 571px;
    width: 112px;
    height: 22px;
  }

  .advantage_img .quality_img > img {
    width: 100%;
    height: 100%;
  }

  .quality_content {
    position: absolute;
    top: 196px;
    right: 512px;
    width: 452px;
    height: 130px;
    padding: 30px 0;
    margin-top: 8px;
    font-size: 18px;
    font-weight: normal;
    color: #404040;
    line-height: 30px;
  }

  .cruise {
    position: absolute;
    top: 146px;
    right: -69px;
    width: 500px;
  }

  .cruise img {
    width: 100%;
  }

  .go_seleted {
    position: absolute;
    top: 395px;
    right: 681px;
    width: 290px;
    height: 52px;
    padding-left: 35px;
    background: #37108b;
    border-radius: 39px;
    line-height: 52px;
    font-size: 20px;
    font-weight: normal;
    color: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.69);
    cursor: pointer;
    &:hover {
      top: 393px;
      transition: all 0.25s linear;
    }
  }

  .source_img {
    position: absolute;
    top: 689px;
    right: 588px;
    width: 470px;
  }

  .source_img img {
    width: 100%;
  }

  .source_title {
    position: absolute;
    top: 702px;
    right: 205px;
    width: 300px;
    height: 46px;
    font-size: 32px;
    font-weight: normal;
    color: #373737;
    line-height: 46px;
  }

  .source_right_img {
    position: absolute;
    top: 722px;
    right: 32px;
    width: 112px;
    height: 20px;
  }

  .source_right_img > img {
    width: 100%;
  }

  .source_content {
    position: absolute;
    top: 785px;
    right: -40px;
    width: 549px;
    height: 205px;
    font-size: 18px;
    font-weight: normal;
    color: #404040;
    line-height: 30px;
  }

  .start_sourcing {
    position: absolute;
    top: 979px;
    right: 294px;
    width: 210px;
    height: 52px;
    padding-left: 32px;
    background: #ffd34a;
    border-radius: 34px;
    font-size: 20px;
    font-weight: normal;
    color: #ffffff;
    line-height: 52px;
    box-shadow: 0px 2px 8px 0px rgba(66, 62, 58, 0.34);
    cursor: pointer;
    &:hover {
      top: 975px;
      transition: all 0.5s;
    }
  }

  .fast_shipping {
    position: absolute;
    top: 1283px;
    right: 683px;
    width: 285px;
    height: 46px;
    font-size: 32px;
    font-weight: normal;
    color: #373737;
    line-height: 46px;
  }
  .shipping-learn-more {
    position: absolute;
    top: 1630px;
    right: 833px;
    width: 134px;
    height: 34px;
    font-size: 24px;
    color: #06095b;
    line-height: 34px;
    text-decoration: underline;
    cursor: pointer;
    &:active {
      color: #37108b;
    }
  }

  .shipping_right_img {
    position: absolute;
    top: 1301px;
    right: 569px;
    width: 112px;
    height: 20px;
  }

  .shipping_right_img > img {
    width: 100%;
    height: 100%;
  }

  .shipping_content {
    position: absolute;
    top: 1371px;
    right: 524px;
    width: 444px;
    height: 296px;
    font-size: 18px;
    font-weight: normal;
    color: #404040;
    line-height: 30px;
  }

  .fast_shipping_img {
    position: absolute;
    top: 1262px;
    right: -69px;
    width: 500px;
  }

  .fast_shipping_img > img {
    width: 100%;
  }

  .content_deep {
    position: relative;
    display: flex;
  }

  .advantage_content .last_img {
    position: absolute;
    top: -95px;
    left: -99px;
    width: 500px !important;
    z-index: 9;
    img {
      width: 100%;
    }
  }

  .advantage_content .last_img {
    width: 100%;
    height: 100%;
  }

  .content_deep .wholesale_program {
    display: flex;
    height: 52px;
    margin-top: -93px;
  }

  .last_right {
    margin-left: 496px;
    .learn_more {
      margin-top: -50px;
      font-size: 24px;
      color: #06095b;
      line-height: 34px;
      text-decoration: underline;
      cursor: pointer;
      &:active {
        color: #37108b;
      }
    }
  }

  .wholesale_program .wholesale {
    width: 322px;
    height: 52px;
    font-size: 32px;
    font-weight: normal;
    color: #373737;
    line-height: 52px;
  }

  .wholesale_program img {
    width: 112px;
    height: 20px;
    margin: 20px 0 0 39px;
  }

  .wholesale_contet {
    width: 482px;
    height: 278px;
    margin-top: 32px;
    font-size: 18px;
    font-weight: normal;
    color: #404040;
    line-height: 30px;
  }

  .empowers {
    height: 64px;
    padding: 0 52px;
    font-size: 48px;
    font-weight: 550;
    color: #404040;
    text-align: center;
    line-height: 60px;
  }

  .iYoowe_business {
    height: 717px;
    width: 100%;
    margin-top: -51px;
    background: url(../../assets/img/image/beijing3.png);
    background-size: 100% 100%;
  }

  .iYoowe_business .business_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    position: relative;
    top: 150px;
    width: 941px;
    height: 421px;
    margin-left: 50%;
    transform: translate(-50%);
  }

  .business_list > div {
    position: relative;
    width: 204px;
    height: 194px;
    background: #ffffff;
    box-shadow: 4px 5px 13px 10px rgba(190, 190, 190, 0.31);
    border-radius: 30px;
  }

  .business_list > div > div {
    position: absolute;
    top: 30px;
    left: 59px;
  }

  .business_list > div {
    &:nth-of-type(1) > div {
      width: 80px;
      height: 77px;
      background: url(/img/9.png) no-repeat 0 0;
      background-size: cover;
    }
    &:nth-of-type(2) > div {
      width: 80px;
      height: 77px;
      background: url(/img/9.png) no-repeat 0 -130px;
      background-size: cover;
    }
    &:nth-of-type(3) > div {
      width: 80px;
      height: 77px;
      background: url(/img/9.png) no-repeat 0 -260px;
      background-size: cover;
    }
    &:nth-of-type(4) > div {
      width: 80px;
      height: 77px;
      background: url(/img/9.png) no-repeat 0 -390px;
      background-size: cover;
    }
    &:nth-of-type(5) > div {
      width: 80px;
      height: 77px;
      background: url(/img/9.png) no-repeat 0 -520px;
      background-size: cover;
    }
    &:nth-of-type(6) > div {
      width: 80px;
      height: 77px;
      background: url(/img/9.png) no-repeat 0 -660px;
      background-size: cover;
    }
    &:nth-of-type(7) > div {
      width: 80px;
      height: 77px;
      background: url(/img/9.png) no-repeat 0 -797px;
      background-size: cover;
    }
    &:nth-of-type(8) > div {
      width: 80px;
      height: 77px;
      background: url(/img/9.png) no-repeat 0 -933px;
      background-size: cover;
    }
  }

  .business_list > div p {
    position: absolute;
    bottom: 40px;
    left: 32px;
    width: 138px;
    height: 40px;
    padding: 8px 0;
    font-size: 18px;
    font-weight: normal;
    color: #2d2d2d;
    text-align: center;
  }

  .business_list > div:nth-of-type(3) p {
    width: 129px;
    left: 38px;
  }

  .business_list > div:nth-of-type(4) p {
    width: 129px;
    left: 33px;
  }

  .business_list > div:nth-of-type(6) p {
    width: 145px;
  }

  .business_list > div:nth-of-type(8) p {
    width: 158px;
    left: 23px;
  }

  .dropshipping {
    width: 1001px;
    height: 99px;
    margin-top: 111px;
    margin-left: 50%;
    transform: translate(-50%);
    font-size: 46px;
    font-weight: normal;
    color: #404040;
    line-height: 99px;
    text-align: center;
  }

  /* shipping_steps */
  .shipping_steps {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    height: 258px;
    margin-top: 96px;
    margin-bottom: 123px;
    margin-left: 50%;
    transform: translate(-50%);
    & > div:nth-of-type(1) > div:nth-of-type(2) {
      background: url(/img/7.png) no-repeat 0 0;
      background-size: 107px 585px;
    }
    & > div:nth-of-type(3) > div:nth-of-type(2) {
      background: url(/img/7.png) no-repeat 0 -136px;
      background-size: 107px 585px;
    }
    & > div:nth-of-type(5) > div:nth-of-type(2) {
      background: url(/img/7.png) no-repeat 0 -260px;
      background-size: 107px 585px;
    }
    & > div:nth-of-type(7) > div:nth-of-type(2) {
      background: url(/img/7.png) no-repeat 0 -385px;
      background-size: 107px 585px;
    }
    & > div:nth-of-type(9) > div:nth-of-type(2) {
      background: url(/img/7.png) no-repeat 0 -516px;
      background-size: 107px 585px;
    }
  }

  .shipping_steps > div:nth-of-type(2n + 1) {
    position: relative;
    width: 180px;
    height: 258px;
  }

  .shipping_steps > div:nth-of-type(2n) {
    height: 258px;
    line-height: 258px;
    text-align: center;
  }

  .shipping_steps > div:nth-of-type(2n) > img {
    height: 30px;
    width: 65px;
  }

  .shipping_steps > div:nth-of-type(2n + 1) > div:nth-of-type(1) {
    position: absolute;
    top: 26px;
    left: 28px;
    width: 79px;
    height: 27px;
    font-size: 24px;
    font-weight: normal;
    color: #333333;
    line-height: 27px;
  }

  .shipping_steps > div:nth-of-type(2n + 1) > div:nth-of-type(2) {
    position: absolute;
    top: 89px;
    left: 38px;
    width: 107px;
    height: 73px;
  }
  .shipping_steps > div:nth-of-type(3) > div:nth-of-type(2) {
    left: 28px;
  }
  // .shipping_steps > div:nth-of-type(2n + 1) > div:nth-of-type(2) > img {
  //   width: 100%;
  //   height: 100%;
  // }
  // .shipping_steps > div:nth-of-type(1) > div:nth-of-type(2) > img {
  //   transform: scale(1.6);
  // }
  .shipping_steps > div:nth-of-type(2n + 1) > div:nth-of-type(3) {
    position: absolute;
    bottom: 40px;
    left: 36px;
    width: 115px;
    height: 43px;
    padding: 8px 0;
    font-size: 19px;
    font-weight: normal;
    color: #000000;
    line-height: 23px;
    text-align: center;
  }
  .shipping_steps > div:nth-of-type(2n + 1):hover {
    background: #ffffff;
    box-shadow: 4px 5px 13px 10px rgba(190, 190, 190, 0.31);
    border-radius: 30px;
  }
}
</style>
