<template>
  <div
    class="!bg-cover !bg-center"
    :style="'background: url(' + src + ') no-repeat; z-index: -1'"
  >
    <div
      class="relative top-0px left-0px w-full h-full rounded-2px"
      :style="'background-color: ' + maskColor + '; z-index: 0'"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bgImg',
  props: {
    src: String,
    maskColor: { Type: String, default: 'transparent' },
  },
};
</script>
