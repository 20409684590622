import { unix, format } from "dayjs"


/**
 * 格式化日期
 * @param {Integer} timestamp 时间戳
 * @param {String} format 格式
 * @returns {String}}
 * @author Verdient。
 */
export function formatDate(timestamp, format = 'YYYY-MM-DD') {
    return unix(timestamp).format(format);
}

/**
 * 格式化日期和时间
 * @param {Integer} timestamp 时间戳
 * @param {String} format 格式
 * @returns {String}}
 * @author Verdient。
 */
export function formatDateTime(timestamp, format = 'YYYY-MM-DD HH:mm:ss') {
    return unix(timestamp).format(format);
}

export default {
    install(Vue) {
        Vue.prototype.$formatDate = formatDate;
        Vue.prototype.$formatDateTime = formatDateTime;
    }
}
