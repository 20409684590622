<template>
  <div class="w-1200px m-auto">
    <bg-img
      src="/img/23.png"
      maskColor="#00000033"
      class="rounded-2px select-none"
    >
      <div class="text-white px-80px py-50px">
        <div class="text-3xl font-bold">Everything more than dropshipping</div>
        <div class="w-400px py-24px">
          We provide end-to-end logistics services to enhance your brand
          awareness and customer satisfaction.
        </div>
        <div class="text-lg font-bold">* Free Storage</div>
        <div class="text-lg font-bold">* Custom Packaging</div>
        <div class="text-lg font-bold">* FBA, B2B Worry-Free Shipping</div>
      </div>
    </bg-img>
    <div class="mt-24px">
      <div class="text-xl font-bold text-[#323639]">Real Client Cases:</div>
      <el-table
        v-loading="isTableLoading"
        class="mt-12px rounded-2px"
        :data="realtimeCases"
        header-cell-class-name="!bg-[#f0f0f0] text-black !py-16px"
      >
        <el-table-column
          align="center"
          label="Method"
          prop="logistic_channel_name"
        ></el-table-column>
        <el-table-column
          align="center"
          label="Country"
          prop="to_country_code"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          label="Weight(kg)"
          prop="weight"
          width="100"
        >
          <template slot-scope="{ row }">
            {{ parseFloat(row.weight / 1000).toFixed(3) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="Shipping Cost"
          prop="shipping_cost"
          width="120"
        >
          <template slot-scope="{ row }"> $ {{ row.shipping_cost }} </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="Delivery Time"
          prop="delivery_days"
          width="120"
        >
          <template slot-scope="{ row }">
            {{ row.delivery_days }} days
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="Tracking Number"
          prop="tracking_number"
          width="260"
        ></el-table-column>
        <el-table-column
          align="center"
          label="Product"
          prop="product"
          width="180"
        ></el-table-column>
      </el-table>
      <el-card class="mt-24px rounded-24px">
        <div
          class="px-36px py-24px"
          style="
            background: url(/svg/world-map.svg) no-repeat;
            background-size: 60%;
            background-position: bottom right;
          "
        >
          <div class="font-bold text-2xl">GET A FREE QUOTE</div>
          <div class="text-sm text-[#909399] mt-8px">
            Leave a message to inquire about tailor-made fulfillment solutions
            for any business model!
          </div>
          <el-form
            ref="formRef"
            class="mt-24px"
            label-position="top"
            :model="formData"
            :rules="rules"
          >
            <el-row>
              <el-col :span="8">
                <el-form-item prop="name" label="Name" required class="pr-12px">
                  <el-input v-model="formData.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="email"
                  label="E-mail"
                  required
                  class="px-6px"
                >
                  <el-input v-model="formData.email"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="phone"
                  label="Phone"
                  required
                  class="pl-12px"
                >
                  <el-input v-model="formData.phone"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item prop="whats_app" label="WhatsApp" class="pr-12px">
                  <el-input v-model="formData.whats_app"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="facebook" label="Facebook" class="px-6px">
                  <el-input v-model="formData.facebook"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="skype" label="Skype" class="pl-12px">
                  <el-input v-model="formData.skype"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item
              prop="interested_services"
              label="Service interested"
              required
            >
              <el-checkbox-group
                class="leading-0"
                v-model="formData.interested_services"
                :max="1"
              >
                <el-checkbox label="wholesale">WHOLESALE</el-checkbox>
                <el-checkbox label="dropshipping">DROPSHIPPING</el-checkbox>
                <el-checkbox label="fulfillment service"
                  >FULFILLMENT SERVICE</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
          </el-form>
          <el-button
            size="small"
            type="primary"
            class="w-180px text-17px font-bold mt-12px"
            :loading="isSubmitLoading"
            @click="submit"
            >Submit</el-button
          >
        </div>
      </el-card>
      <logistics-wall class="mt-48px"></logistics-wall>
    </div>
  </div>
</template>

<script>
import BgImg from '@/views/components/bg-img/index.vue';
import LogisticsWall from './components/logistics-wall.vue';
export default {
  components: {
    BgImg,
    LogisticsWall,
  },
  data() {
    return {
      isTableLoading: false,
      isSubmitLoading: false,
      realtimeCases: [],
      formData: {
        name: null,
        email: null,
        phone: null,
        whats_app: null,
        skype: null,
        facebook: null,
        interested_services: [],
      },
      rules: {
        name: [
          { required: true, message: 'Please enter Name', trigger: 'blur' },
        ],
        email: [
          { required: true, message: 'Please enter E-mail', trigger: 'blur' },
          {
            type: 'email',
            message: 'Please enter a valid E-mail',
            trigger: 'blur',
          },
        ],
        phone: [
          { required: true, message: 'Please enter Phone', trigger: 'blur' },
        ],
        interested_services: [
          {
            required: true,
            message: 'Please select at least one service you are interested in',
          },
        ],
      },
    };
  },
  created() {
    this.fetchRealtimeCases();
  },
  methods: {
    fetchRealtimeCases() {
      this.isTableLoading = true;
      this.$request
        .get('logistics/realtime-case')
        .then((res) => {
          this.realtimeCases = res.data;
        })
        .catch((e) => this.$message.error(e.message))
        .finally(() => {
          this.isTableLoading = false;
        });
    },
    submit() {
      this.$refs.formRef
        .validate()
        .then(() => {
          this.isSubmitLoading = true;
          this.$request
            .post('marketing-form/shipping', {
              name: this.formData.name,
              email: this.formData.email,
              phone: this.formData.phone,
              whats_app: this.formData.whats_app,
              skype: this.formData.skype,
              facebook: this.formData.facebook,
              interested_services: this.formData.interested_services,
            })
            .then((res) => {
              this.$message.success(res.message);
              this.$refs.formRef.resetFields();
            })
            .catch((e) => this.$message.error(e.message))
            .finally(() => {
              this.isSubmitLoading = false;
            });
        })
        .catch((_) => _);
    },
  },
};
</script>
