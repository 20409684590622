<template>
  <div class="contain">
    <div class="!bg-[#fafafa]">
      <div class="bg-[#f1f4f2] flex justify-center py-60px">
        <div class="w-800px">
          <div
            class="text-4.25rem font-500 text-[#2ea4b2] w-900px leading-snug"
          >
            Design and Sell Custom Products Online
          </div>
          <el-row class="mt-24px text-2xl w-730px">
            <el-col :span="12">
              <icon icon="lucide:check-circle" width="18" class="mr-8px"></icon>
              Free to use</el-col
            >
            <el-col :span="12">
              <icon icon="lucide:check-circle" width="18" class="mr-8px"></icon>
              Brand your business
            </el-col>
            <el-col :span="12" class="mt-12px">
              <icon icon="lucide:check-circle" width="18" class="mr-8px"></icon>
              Generate bigger profit
            </el-col>
            <el-col :span="12" class="mt-12px">
              <icon icon="lucide:check-circle" width="18" class="mr-8px"></icon>
              Turn ideas into SALES
            </el-col>
          </el-row>
          <div
            class="bg-[#37108b] w-240px text-white text-xl h-50px rounded-50px flex items-center justify-center mt-48px cursor-pointer"
            @click="gotoApp('register', 'clickRegisterButton')"
          >
            Get Started
          </div>
        </div>
        <div class="w-400px">
          <img src="/img/10.png" />
          <img src="/img/point.png" class="animate-slide -mt-110px ml-60px" />
        </div>
      </div>
      <el-carousel
        trigger="click"
        height="600px"
        arrow="never"
        indicator-position="outside"
      >
        <el-carousel-item>
          <div class="h-full w-full flex justify-center items-center">
            <img src="/img/11.png" />
            <div class="ml-60px">
              <div class="text-6xl font-bold">
                Method <span class="text-8xl">01</span>
              </div>
              <div class="text-2.5rem font-bold mt-6px">
                Brand Your Business
              </div>
              <div
                class="mt-12px text-2xl w-450px text-[#404040] leading-relaxed"
              >
                Brand your online business for a greater value. Print your brand
                name & logo on you products & package.
              </div>
              <div
                class="underline text-[#487c57] text-xl mt-36px cursor-pointer flex items-center"
                @click="gotoApp('register', 'clickRegisterButton')"
              >
                Consult with our agent
                <icon
                  icon="fa6-solid:up-right-from-square"
                  width="18"
                  class="ml-10px"
                ></icon>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="h-full w-full flex justify-center items-center">
            <img src="/img/14.png" />
            <div class="-ml-60px flex flex-col items-end w-600px">
              <div class="text-6xl font-bold">
                Method <span class="text-8xl">02</span>
              </div>
              <div
                class="bg-[#f1f2f5] py-38px px-65px mt-36px rounded-10px shadow"
                style="--tw-shadow: 2px 4px 23px 4px rgb(190 190 190 / 50%)"
              >
                <div class="font-bold text-2.6rem">Print on Your Demand</div>
                <div class="mt-24px text-[#404040] leading-relaxed text-2xl">
                  Turn your designs into your custom products, easily add your
                  ideas on a wide range of products.
                </div>
                <div
                  class="underline text-[#487c57] text-xl mt-36px cursor-pointer flex items-center"
                  @click="gotoApp('register', 'clickRegisterButton')"
                >
                  Go to iYoowe Selected
                  <icon
                    icon="fa6-solid:up-right-from-square"
                    width="18"
                    class="ml-10px"
                  ></icon>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="h-full w-full flex justify-center items-center">
            <img src="/img/20.png" />
            <div class="ml-60px">
              <div class="text-6xl font-bold">
                You Design <span class="text-8xl font-normal">&</span>
              </div>
              <div class="text-2.5rem font-bold mt-6px">
                Leave the rest to us
              </div>
              <div
                class="mt-24px text-2xl w-450px text-[#404040] leading-relaxed"
              >
                Once an order is placed, we will handle all the production and
                the following fulfillment for you.
              </div>
              <div
                class="underline text-[#487c57] text-xl mt-36px cursor-pointer flex items-center"
                @click="gotoApp('register', 'clickRegisterButton')"
              >
                Go to iYoowe Selected
                <icon
                  icon="fa6-solid:up-right-from-square"
                  width="18"
                  class="ml-10px"
                ></icon>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="make-money mt-24px">
        <div><img src="/img/15.png" alt="" /></div>
        <div>
          <div class="pt-91px pl-117px">
            <p>Make <span>Money</span></p>
            <p>with Your Own Design</p>
            <p>Maximize your profit with custom products.</p>
            <div>
              <div>
                <div>
                  <p>You sell a bag</p>
                  <p>$38</p>
                </div>
                <div>
                  <p>The cost you need</p>
                  <p>$10</p>
                </div>
              </div>
              <div></div>
              <div>
                <p>Your profit</p>
                <p>$28</p>
              </div>
            </div>
            <p @click="showChat">GET QUOTE FROM US</p>
          </div>
        </div>
      </div>
      <div class="iyoowe-select">
        <div class="scale-up">
          <p>SCALE UP</p>
          <p>with iYoowe Selected</p>
        </div>
        <div>
          <img src="/img/16.png" alt="" />
        </div>
        <div class="flex items-center justify-center">
          <div class="text-2xl text-[#414141]">
            Need more product ideas? Follow us @
          </div>
          <div @click="gotoInstagram" class="cursor-pointer mt-12px ml-4px">
            <icon icon="logos:instagram" width="120"></icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue2';
import { gotoApp, gotoInstagram } from '../../utils/location';
import { showChat } from '../../utils/chat';

export default {
  name: 'pod',
  components: {
    Icon,
  },
  methods: {
    showChat,
    gotoInstagram,
    gotoApp,
  },
};
</script>

<style lang="scss" scoped>
.design-cell {
  position: relative;
  width: 100%;
  height: 585px;
  background: #f1f4f2;
  .design {
    position: relative;
    top: 84px;
    display: flex;
    // justify-content: space-between;
    width: 1200px;
    margin: 0 auto;
    & > div:nth-of-type(1) {
      & > p:nth-of-type(1) {
        position: relative;
        width: 824px;
        height: 162px;
        font-size: 68px;
        font-weight: 500;
        color: #2ea4b2;
        line-height: 81px;
      }
      & > div:nth-of-type(1) {
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        align-content: space-between;
        width: 680px;
        height: 94px;
        margin-top: 31px;
        & > div {
          display: flex;
          align-items: center;
          width: 334px;
          height: 40px;
          font-size: 24px;
          img {
            border: 1px dashed #979779;
            margin-right: 20px;
          }
        }
      }
    }
    & > div:nth-of-type(2) {
      position: relative;
      margin-left: -59px;
      img {
        width: 443px;
        height: 429px;
      }
      @keyframes slidein {
        0% {
          transform: translate(22px, 22px);
        }
        50% {
          transform: translate(-22px, -22px);
        }
        100% {
          transform: translate(22px, 22px);
        }
      }
      & > div {
        position: absolute;
        left: 59px;
        bottom: 22px;
        width: 81px;
        height: 91px;
        animation: slidein 3s infinite;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.get-start {
  width: 1200px;
  margin: 0 auto;
  p {
    width: 237px;
    height: 47px;
    margin-top: -160px;
    margin-bottom: 115px;
    border-radius: 23px;
    background: #37108b;
    color: #fff;
    font-size: 21px;
    line-height: 47px;
    text-align: center;
  }
}

.circle {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 108px;
  height: 16px;
  margin: -113px auto 97px;
  li {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #d7d7d7;
    list-style: none;
    // cursor: pointer;
  }
  & > li:nth-of-type(1) {
    background: #000a47;
  }
}

.contain {
  width: 100%;
  height: 100%;
  // overflow-x: hidden;
  // &::-webkit-scrollbar {
  //   display: none;
  // }

  .make-money {
    display: flex;
    width: 100%;
    height: 728px;
    background: #a9d8b7;
    & > div:nth-of-type(1) {
      position: relative;
      flex: 1;
      img {
        position: absolute;
        top: 104px;
        right: 125px;
        width: 473px;
        height: 557px;
      }
    }
    & > div:nth-of-type(2) {
      flex: 1;
      background: #49696b;
      border-radius: 32px 0px 0px 32px;
      & > div {
        & > p:nth-of-type(1) {
          width: 409px;
          height: 89px;
          font-size: 60px;
          font-weight: bold;
          color: #ffffff;
          line-height: 69px;
          span {
            color: #66ff89;
          }
        }
        & > p:nth-of-type(2) {
          width: 615px;
          height: 68px;
          font-size: 44px;
          font-weight: bold;
          color: #ffffff;
          line-height: 50px;
        }
        & > p:nth-of-type(3) {
          width: 501px;
          height: 48px;
          font-size: 24px;
          color: #d5d6da;
          line-height: 39px;
        }
        & > div:nth-of-type(1) {
          width: 485px;
          height: 226px;
          margin-top: 21px;
          padding: 33px 58px;
          background: #6f8081;
          border-radius: 16px;
          box-sizing: border-box;
          & > div:nth-of-type(1) {
            width: 100%;
            height: 100px;
            & > div {
              display: flex;
              justify-content: space-between;
              height: 44px;
              color: #ffffff;
              font-size: 22px;
              line-height: 26px;
              & > p:nth-of-type(1) {
                width: 485px;
              }
              & > p:nth-of-type(2) {
                width: 74px;
              }
            }
            & > div:nth-of-type(2) {
              margin-top: 11px;
            }
          }
          & > div:nth-of-type(2) {
            width: 396px;
            height: 1px;
            margin-top: 9px;
            margin-left: -13px;
            border: 1px solid #ffffff;
            box-sizing: border-box;
          }
          & > div:nth-of-type(3) {
            display: flex;
            justify-content: space-between;
            height: 44px;
            margin-top: 26px;
            color: #00ff79;
            font-size: 22px;
            line-height: 26px;
            & > p:nth-of-type(1) {
              width: 485px;
            }
            & > p:nth-of-type(2) {
              width: 74px;
            }
          }
        }
        & > p:nth-of-type(4) {
          width: 277px;
          height: 68px;
          margin-top: 42px;
          background: #ededed;
          border-radius: 34px;
          font-size: 20px;
          font-weight: bold;
          color: #3a5a43;
          line-height: 68px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
  .iyoowe-select {
    width: 100%;
    height: 925px;
    background: #fafafa;
    .scale-up {
      width: 1200px;
      height: 204px;
      margin: 81px auto 0;
      font-size: 72px;
      font-weight: bold;
      line-height: 68px;
      & > p:nth-of-type(1) {
        color: #49696b;
      }
      & > p:nth-of-type(2) {
        font-size: 60px;
        color: #333333;
      }
    }
    & > div:nth-of-type(2) {
      width: 959px;
      height: 540px;
      margin: -15px auto 0;
    }
    & > div:nth-of-type(3) {
      width: 765px;
      height: 64px;
      margin: 30px auto;
      display: flex;
      & > p {
        width: 540px;
        font-size: 30px;
        color: #414141;
        line-height: 45px;
      }
      & > img {
        cursor: pointer;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
::v-deep {
  .el-carousel__indicator--horizontal {
    padding: 12px 16px;
  }
  .el-carousel__button {
    width: 16px;
    height: 16px;
    border-radius: 16px;
  }
  .el-carousel__indicator.is-active button {
    opacity: 1;
    background: #000a47;
  }
  .el-carousel__indicator button {
    background: #d7d7d7;
  }
  .animate-slide {
    @keyframes slide {
      0% {
        transform: translate(22px, 22px);
      }
      50% {
        transform: translate(-22px, -22px);
      }
      100% {
        transform: translate(22px, 22px);
      }
    }
    -webkit-animation: slide 1s infinite;
    animation: slide 3s infinite;
  }
}
</style>
