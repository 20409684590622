<template>
  <div>
    <div
      style="
        background-image: linear-gradient(124deg, var(--tw-gradient-stops));
      "
      class="from-[#AF97F5] to-[#7476EF] py-16px shadow"
    >
      <div class="flex justify-center items-center font-bold text-white">
        <icon
          icon="fluent:vehicle-truck-cube-24-filled"
          class="mr-10px"
          width="20"
        ></icon>
        Sign up today and get free shipping for the 1st order!
      </div>
    </div>
    <div class="w-1200px m-auto flex">
      <div class="w-760px pr-24px">
        <el-card class="my-20px rounded-16px border-none px-24px py-8px">
          <div class="text-2xl font-bold pt-8px pb-36px">
            Shipping Calculation
          </div>
          <el-form
            ref="form_shipping"
            size="small"
            :model="form_shipping"
            :rules="rules"
            label-position="left"
            label-width="90px"
          >
            <el-row :gutter="40">
              <el-col :span="14" class="pr-6px">
                <el-form-item prop="to_country" label="Ship to">
                  <el-select
                    class="w-full"
                    v-model="form_shipping.to_country"
                    placeholder="Please select country of destination"
                    filterable
                    :filter-method="dataFilter"
                  >
                    <el-option
                      v-for="(item, ind) in country_list"
                      :key="ind"
                      :label="item.label"
                      :value="item.code"
                    >
                      <div class="flex justify-between items-center">
                        <div class="font-bold">{{ item.label }}</div>
                        <div class="text-12px font-light">
                          {{ item.code }}
                        </div>
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="size">
                  <template #label>
                    <span class="pl-11px">Size(cm)</span>
                  </template>
                  <el-row>
                    <el-col :span="8" class="pr-4px">
                      <el-input
                        placeholder="Length"
                        v-model="form_shipping.length"
                      ></el-input>
                    </el-col>
                    <el-col :span="8" class="px-2px">
                      <el-input
                        placeholder="Width"
                        v-model="form_shipping.width"
                      ></el-input>
                    </el-col>
                    <el-col :span="8" class="pl-4px">
                      <el-input
                        placeholder="Height"
                        v-model="form_shipping.height"
                      ></el-input>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="10" class="pl-6px">
                <el-form-item prop="weight" label="Weight(g)">
                  <el-input
                    v-model="form_shipping.weight"
                    class="w-full"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="EAT" label="" label-width="0px">
                  <div class="flex items-center">
                    <div
                      class="w-90px flex-shrink-0 pl-11px el-form-item__label"
                    >
                      EAT
                    </div>
                    <div class="flex">
                      <el-input
                        v-model="form_shipping.min_day"
                        class="w-full"
                      ></el-input>
                      <div class="px-8px">-</div>
                      <el-input
                        v-model="form_shipping.max_day"
                        class="w-full"
                      ></el-input>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="Product Attribute" label-width="140px">
              <el-checkbox-group v-model="form_shipping.attribute">
                <el-checkbox label="1">Ordinary</el-checkbox>
                <el-checkbox label="2">Electronic</el-checkbox>
                <el-checkbox label="5">Liquid</el-checkbox>
                <el-checkbox label="6">Paste</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
          <div class="w-full flex flex-col justify-center items-center">
            <el-button
              class="
                mt-24px
                w-200px
                !bg-[#37108b]
                !text-white
                !hover:text-white
                !border-none
                rounded-6px
                !hover:bg-[#37108b]
                tracking-1px
                text-16px
              "
              icon="el-icon-search"
              :loading="loading"
              @click="calculate"
            >
              Calculate
            </el-button>
          </div>
        </el-card>
        <div class="mb-50px">
          <el-table
            class="w-full rounded-3px"
            :data="available_channels"
            empty-text="No Data"
            :header-cell-style="{
              background: '#f4f4f5',
              color: '#606266',
              height: '70px',
            }"
          >
            <el-table-column
              align="center"
              label="Shipping Method"
              prop="logistic_channel_name"
            >
              <template slot-scope="{ row }">
                <div>{{ row.logistic_channel_name[0] }}</div>
                <div
                  v-if="row.logistic_channel_name[1]"
                  class="text-xs text-[#606266]"
                >
                  {{ row.logistic_channel_name[1] }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="Shipping Cost"
              prop="postage"
            >
              <template slot-scope="{ row }">
                <el-row
                  >{{ Number(row.freight).toFixed(2) }}
                  {{ row.currency }}</el-row
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="Delivery Time"
              :formatter="formatDeliveryTime"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="w-440px">
        <el-card
          class="my-20px rounded-16px border-none px-18px pt-8px pb-16px"
        >
          <div style="background: url('/svg/world-map.svg') no-repeat">
            <div class="font-bold text-[#4E4E4E]">
              Meet your needs with the most suitable method - worldwide fast
              shipping at a better price.
            </div>
            <el-carousel
              class="mt-12px"
              trigger="click"
              height="300px"
              indicator-position="outside"
            >
              <el-carousel-item
                v-for="(item, index) in realtimeCases"
                :key="index"
              >
                <div
                  class="
                    bg-black bg-opacity-7
                    h-full
                    rounded-16px
                    px-20px
                    py-18px
                    text-[#323639] text-sm
                    select-none
                    mx-32px
                  "
                >
                  <div class="font-bold text-lg">Real-Time Case</div>
                  <div class="text-[#656565] py-6px font-light">Method:</div>
                  <div>{{ item.logistic_channel_name }}</div>
                  <div class="text-[#656565] py-6px font-light">
                    Tracking Number:
                  </div>
                  <div>{{ item.tracking_number }}</div>
                  <div class="py-6px flex items-center">
                    <div class="text-[#656565] font-light mr-6px">Deliver:</div>
                    <icon
                      :icon="
                        'cif:' + String(item.from_country_code).toLowerCase()
                      "
                      class="rounded-2px"
                    ></icon>
                    <icon
                      icon="akar-icons:arrow-right"
                      color="#656565"
                      class="mx-6px"
                    ></icon>
                    <icon
                      :icon="
                        'cif:' + String(item.to_country_code).toLowerCase()
                      "
                      class="rounded-2px"
                    ></icon>
                  </div>
                  <div class="py-6px">
                    <span class="text-[#656565] font-light">Weight:</span>
                    {{ item.weight }} g
                  </div>
                  <div class="py-6px">
                    <span class="text-[#656565] font-light"
                      >Delivery Time:</span
                    >
                    {{ item.delivery_days }} days
                  </div>
                  <div class="py-6px">
                    <span class="text-[#656565] font-light"
                      >Shipping Cost:</span
                    >
                    {{ item.shipping_cost }} USD
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <div
              class="
                mt-12px
                bg-[#4AB75F]
                text-white text-center
                w-240px
                m-auto
                py-7px
                rounded-6px
                cursor-pointer
              "
              @click="$router.push('/shipping/request-quote')"
            >
              GET A FREE QUOTE
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <logistics-wall></logistics-wall>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";
import LogisticsWall from "./components/logistics-wall.vue";

export default {
  name: "shipping",
  components: { Icon, LogisticsWall },
  data() {
    return {
      loading: false,
      form_shipping: {
        to_country: null,
        attribute: [],
        weight: null,
        length: null,
        width: null,
        height: null,
        min_day: null,
        max_day: null,
      },
      rules: {
        to_country: [
          {
            required: true,
            message: "Please select country of destination",
            trigger: "blur",
          },
        ],
        weight: [
          {
            required: true,
            message: "Please input package weight",
            trigger: "blur",
          },
        ],
        size: [
          {
            validator: (rule, value, callback) => {
              if (
                !!this.form_shipping.length &&
                !!this.form_shipping.width &&
                !!this.form_shipping.height
              ) {
                if (!/^[0-9]+$/.test(this.form_shipping.length)) {
                  return callback(
                    new Error("Length must be a positive integer")
                  );
                }
                if (!/^[0-9]+$/.test(this.form_shipping.width)) {
                  return callback(
                    new Error("Width must be a positive integer")
                  );
                }
                if (!/^[0-9]+$/.test(this.form_shipping.height)) {
                  return callback(
                    new Error("Height must be a positive integer")
                  );
                }
                return callback();
              }
              if (
                !this.form_shipping.length &&
                !this.form_shipping.width &&
                !this.form_shipping.height
              ) {
                return callback();
              }
              callback(new Error("Please complete the input of size data"));
            },
            trigger: "blur",
          },
        ],
      },
      country_list: [],
      copy_country_list: [],
      available_channels: [],
      realtimeCases: [],
    };
  },
  created() {
    this.getCountry();
    this.fetchRealtimeCases();
  },
  methods: {
    fetchRealtimeCases() {
      this.$request
        .get("logistics/realtime-case")
        .then((res) => {
          this.realtimeCases = res.data;
        })
        .catch((e) => this.$message.error(e.message));
    },
    formatDeliveryTime(row) {
      let days = Array.from(new Set([row.min_day, row.max_day])).filter(
        (v) => !!v
      );
      if (days.length > 0) {
        return days.join(" ~ ") + " Days";
      } else {
        return "Please contact customer support for details";
      }
    },
    getCountry() {
      this.$request
        .get("/trial/country-codes")
        .then((res) => {
          this.country_list = res.data;
          this.copy_country_list = res.data;
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
    calculate() {
      this.loading = true;
      this.$refs.form_shipping.validate((valid) => {
        if (valid) {
          this.$request
            .post("/trial/trial", {
              country_code: this.form_shipping.to_country,
              forbidens: this.form_shipping.attribute,
              weight: this.form_shipping.weight,
              length: this.form_shipping.length || undefined,
              width: this.form_shipping.width || undefined,
              height: this.form_shipping.height || undefined,
              min_day: this.form_shipping.min_day || undefined,
              max_day: this.form_shipping.max_day || undefined,
            })
            .then((res) => {
              let result = [];
              for (let row of res.data.available_channels) {
                for (let line of row.logistic_channel_lines) {
                  let name = [row.logistic_channel_name];
                  if (line.logistic_area_name) {
                    name.push(line.logistic_area_name);
                  }
                  result.push(
                    Object.assign({ logistic_channel_name: name }, line)
                  );
                }
              }
              this.available_channels = result;
            })
            .catch((e) => {
              this.$message.error(e.message);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    },
    dataFilter(val) {
      this.form_shipping.to_country = val;
      if (val) {
        this.country_list = this.copy_country_list.filter((item) => {
          if (
            !!~item.code.indexOf(val) ||
            !!~item.code.toUpperCase().indexOf(val.toUpperCase()) ||
            !!~item.label.indexOf(val) ||
            !!~item.label.toUpperCase().indexOf(val.toUpperCase())
          ) {
            return true;
          }
        });
      } else {
        this.country_list = this.copy_country_list;
      }
    },
  },
};
</script>
