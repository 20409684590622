var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex text-normal text-[#595959] select-none libre-franklin"},_vm._l((_vm.menus2),function(menu,index){return _c('div',{class:{ 'ml-32px': index > 0 }},[(Array.isArray(menu.childrens))?_c('el-popover',{attrs:{"placement":"bottom","trigger":"hover"},on:{"show":function($event){_vm.showedSubmenuIndex = index},"after-leave":function($event){_vm.showedSubmenuIndex = -1}}},[_c('div',{staticClass:"cursor-pointer hover:text-[#240A5C] flex items-center hover:font-bold antialiased",class:{
          'font-bold': menu.isActive || _vm.showedSubmenuIndex == index,
          'text-[#240A5C]': menu.isActive || _vm.showedSubmenuIndex == index,
        },attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(menu.label)+" "),_c('icon',{staticClass:"ml-4px",attrs:{"icon":"dashicons:arrow-down-alt2"}})],1),_c('div',{staticClass:"text-1rem libre-franklin"},_vm._l((menu.childrens),function(children){return _c('div',{staticClass:"px-12px cursor-pointer py-8px hover:text-[#240A5C] hover:font-bold menu-item",class:{
            'font-bold': children.isActive,
            'text-[#240A5C]': children.isActive,
          },attrs:{"title":children.label},on:{"click":function($event){return _vm.to(children.path)}}},[_vm._v(" "+_vm._s(children.label)+" ")])}),0)]):_c('div',{staticClass:"cursor-pointer hover:text-[#240A5C] hover:font-bold antialiased menu-item",class:{
        'font-bold': menu.isActive,
        'text-[#240A5C]': menu.isActive,
      },attrs:{"title":menu.label},on:{"click":function($event){return _vm.to(menu.path)}}},[_vm._v(" "+_vm._s(menu.label)+" ")])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }