<template>
  <div class="select-none">
    <div class="bg-[#5759ff] py-48px relative">
      <div class="w-1200px m-auto">
        <div class="text-white text-5xl font-bold">
          Contact us for more information
        </div>
        <div
          class="mt-48px flex items-center bg-[#37108b] w-max px-30px py-16px rounded-40px cursor-pointer"
          @click="showChat"
        >
          <div class="text-white text-2xl">Customer service</div>
          <icon
            class="ml-8px mt-4px"
            icon="charm:arrow-up-right"
            width="32"
            color="white"
          ></icon>
        </div>
      </div>
      <div class="absolute top-0 right-0 overflow-hidden w-175px">
        <icon icon="arcticons:fast-shopping" width="300" color="#6768ff"></icon>
      </div>
    </div>
    <div class="w-full bg-black text-white px-30px py-48px mt-4px">
      <div class="flex w-1200px m-auto justify-between">
        <div class="flex items-center justify-center">
          <div>
            <img src="/svg/logo-white.svg" class="w-200px" />
            <div class="bg-white h-1px w-152px mt-18px mb-12px"></div>
            <div class="w-273px">
              <div class="font-500 text-xl tracking-0.01em">
                Subscribe to our newsletters
              </div>
              <div
                class="text-13px font-light leading-tight mt-6px text-[#e5e5e5]"
              >
                Get the first-hand info about trending products recommendations,
                offers & events
              </div>
              <div class="flex items-center mt-10px">
                <input
                  type="email"
                  placeholder="Enter your email address"
                  class="px-12px text-black text-xs flex-auto outline-none h-32px"
                  v-model="email"
                />
                <div
                  class="bg-[#37108b] h-32px w-84px ml-4px mr-1px text-sm cursor-pointer flex justify-center items-center"
                >
                  <icon
                    icon="line-md:loading-twotone-loop"
                    v-show="isSubscribeLoading"
                  ></icon>
                  <div
                    class="h-32px w-full flex items-center justify-center"
                    @click="handleSubscribeClick"
                    v-show="!isSubscribeLoading"
                  >
                    Subscribe
                  </div>
                </div>
              </div>
              <div
                class="absolute text-xs mt-4px text-[#f44336]"
                v-show="subscribeErrorMessage"
              >
                {{ subscribeErrorMessage }}
              </div>
              <div
                class="absolute text-xs mt-4px text-[#009688]"
                v-show="subscribeSuccessMessage"
              >
                {{ subscribeSuccessMessage }}
              </div>
            </div>
          </div>
        </div>
        <div class="w-700px">
          <div class="text-lg font-bold">
            <el-row>
              <el-col :span="8">
                <span
                  class="cursor-pointer"
                  @click="$router.push({ path: '/aboutUs' }).catch((_) => _)"
                >
                  About Us
                </span>
              </el-col>
              <el-col :span="8">
                <span
                  class="cursor-pointer"
                  @click="$router.push({ path: '/blog' }).catch((_) => _)"
                >
                  Blog
                </span>
              </el-col>
              <el-col :span="8">
                <span
                  class="cursor-pointer"
                  @click="$router.push({ path: '/refund' }).catch((_) => _)"
                >
                  Refund, Return Policy
                </span>
              </el-col>
            </el-row>
            <el-row class="my-12px">
              <el-col :span="8">
                <span
                  class="cursor-pointer"
                  @click="
                    $router.push({
                      name: 'helpCenterDetail',
                      params: {
                        id: '387638548704055296',
                      },
                    })
                  "
                >
                  iYoowe Shipping
                </span>
              </el-col>
              <el-col :span="8">
                <span
                  class="cursor-pointer"
                  @click="$router.push({ path: '/privacy' }).catch((_) => _)"
                >
                  Privacy Policy
                </span>
              </el-col>
              <el-col :span="8">
                <span
                  class="cursor-pointer"
                  @click="$router.push({ path: '/terms' }).catch((_) => _)"
                >
                  Terms of Use
                </span></el-col
              >
            </el-row>
            <el-row>
              <el-col :span="8">
                <span
                  class="cursor-pointer"
                  @click="$router.push({ path: '/helpCenter' }).catch((_) => _)"
                >
                  Help Center
                </span>
              </el-col>
              <el-col :span="8">
                <span class="cursor-pointer" @click="showChat">
                  Contact Us
                </span>
              </el-col>
              <el-col :span="8">
                <div class="flex items-center">
                  <img
                    src="/svg/paypal.svg"
                    class="w-42px h-28px object-cover"
                  />
                  <img
                    src="/svg/mastercard.svg"
                    class="w-42px h-28px object-cover ml-4px"
                  />
                  <img
                    src="/svg/amex.svg"
                    class="w-42px h-28px object-cover ml-4px"
                  />
                  <img
                    src="/svg/visa.svg"
                    class="w-42px h-28px object-cover ml-4px"
                  />
                </div>
              </el-col>
            </el-row>
          </div>
          <el-row class="flex items-center mt-24px">
            <el-col :span="8">
              <div class="flex items-center">
                <icon icon="akar-icons:phone" class="mr-8px"></icon>
                1 (909) 353-3330
              </div>
              <div class="mt-12px flex items-center">
                <icon icon="ant-design:skype-filled" class="mr-8px"></icon>
                +19093533330
              </div>
            </el-col>
            <el-col :span="8">
              <div class="flex items-center">
                <icon icon="ic:round-email" class="mr-8px"></icon>
                <a href="mailto:support@iyoowe.com">support@iyoowe.com</a>
              </div>
              <div class="mt-12px flex items-center">
                <icon icon="akar-icons:whatsapp-fill" class="mr-8px"></icon>
                <span class="cursor-pointer" @click="gotoWhatsApp"
                  >+19093533330</span
                >
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <icon
                  icon="akar-icons:instagram-fill"
                  width="24"
                  class="cursor-pointer"
                  @click.native="gotoInstagram"
                ></icon>
                <icon
                  icon="akar-icons:facebook-fill"
                  width="24"
                  class="cursor-pointer mx-26px"
                  @click.native="gotoFacebook"
                ></icon>
                <icon
                  icon="akar-icons:linkedin-box-fill"
                  width="24"
                  class="cursor-pointer mr-26px"
                  @click.native="gotoLinkedIn"
                ></icon>
                <icon
                  icon="akar-icons:youtube-fill"
                  width="24"
                  class="cursor-pointer"
                  @click.native="gotoYouTube"
                ></icon></div
            ></el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue2';
import { showChat } from '../../../utils/chat';

import {
  gotoInstagram,
  gotoFacebook,
  gotoLinkedIn,
  gotoYouTube,
  gotoWhatsApp,
} from '../../../utils/location';
export default {
  name: 'footers',
  data() {
    return {
      email: '',
      subscribeErrorMessage: '',
      subscribeSuccessMessage: '',
      isSubscribeLoading: false,
    };
  },
  components: {
    Icon,
  },
  methods: {
    showChat,
    gotoInstagram,
    gotoFacebook,
    gotoLinkedIn,
    gotoYouTube,
    gotoWhatsApp,
    handleSubscribeClick() {
      this.isSubscribeLoading = true;
      this.subscribeErrorMessage = '';
      this.subscribeSuccessMessage = '';
      this.$request
        .post('customer-clue/create', {
          email: this.email,
        })
        .then((res) => {
          this.email = '';
          this.subscribeSuccessMessage = res.message;
          setTimeout(() => {
            this.subscribeSuccessMessage = '';
          }, 1500);
        })
        .catch((e) => {
          this.subscribeErrorMessage = e.message;
        })
        .finally(() => {
          this.isSubscribeLoading = false;
        });
    },
  },
};
</script>
