<template>
  <bg-img src="/img/21.png" class="h-320px w-full" mask-color="#00000099">
    <div
      class="flex flex-col items-center justify-between py-70px w-full m-auto select-none w-1200px"
    >
      <div class="font-bold text-7xl text-white">
        Hi, how can we help you today?
      </div>
      <div class="flex items-center w-3/5 mt-60px">
        <input
          :value="value"
          class="outline-none border w-full h-50px p-24px pr-48px rounded-24px"
          @keydown.enter="search"
          @input="handleInput"
        />
        <i
          class="el-icon-search relative right-42px cursor-pointer text-xl text-[#695bd7] font-bold"
          @click="search"
        ></i>
      </div>
    </div>
  </bg-img>
</template>

<script>
import bgImg from '../../../components/bg-img/index.vue';
export default {
  name: 'helpCenterHeader',
  props: {
    value: String,
  },
  components: {
    bgImg,
  },
  methods: {
    search() {
      this.$emit('search', this.value);
    },
    handleInput(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>
