<template>
  <div class="refund">
    <h1 style="text-align: center"><strong>Dispute Policy</strong></h1>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >This refund policy is to be used as a resource by dropshippers who work
        with iYoowe</span
      >&nbsp;
      <span style="color: rgb(102, 102, 102)"
        >Please review the following policies carefully.</span
      >
    </p>
    <p>&nbsp;</p>
    <p>
      <span style="color: rgb(102, 102, 102)">1.&nbsp;</span
      ><strong style="color: rgb(102, 102, 102)"
        >Dispute&nbsp;rules&nbsp;and&nbsp;Procedure</strong
      >
    </p>
    <!-- <p><strong style="color: rgb(102, 102, 102)">&nbsp;</strong></p> -->
    <p><strong style="color: rgb(102, 102, 102)">Dispute rules</strong></p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >You could open dispute twice for each product.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >You could not re-open the dispute anymore if the product refund or
        resend successfully.
      </span>
    </p>
    <p><strong style="color: rgb(102, 102, 102)">Dispute solution</strong></p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >There are two solutions supported if you open a dispute. We will refund
        or resend after confirmation.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >Refund: we refund the product fee，the refund amount shall not exceed
        the total order amount.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >Resend: we resend the product to your customer, the resend product
        amount shall not exceed products dispute amount.</span
      >
    </p>
    <p><strong style="color: rgb(102, 102, 102)">Limitation</strong></p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >1. You could open dispute before shipping.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >2. You could not open dispute when order is shipped out that shipping
        time is not due.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >3. You could open a dispute if the order status is delivered (Your
        customer received the order)</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >4.You could not open dispute if you have already open dispute for the
        same products for twice.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >5.You could not open a dispute when order status is closed.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >6.You could not open a dispute when the product is already in
        dispute.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >7. If there is any discrepancy , iYoowe own the right of
        explanation.</span
      >
    </p>
    <p><strong style="color: rgb(102, 102, 102)">&nbsp;</strong></p>
    <p><strong style="color: rgb(102, 102, 102)">Evidence we need</strong></p>
    <p>
      <span style="color: rgb(51, 51, 51)"
        >We appreciate it all disputes resolved on iYoowe, open dispute on third
        party such as Pay Pal is not encouraged. iYoowe offers a quicker dispute
        solution and will appreciate if you provide:</span
      >
    </p>
    <p>
      <strong style="color: rgb(51, 51, 51)">a.&nbsp;</strong
      ><span style="color: rgb(51, 51, 51)"
        >Photos or videos of the damaged item to prove damage. If the photo
        cannot prove the products are damaged, please upload the video.</span
      >
    </p>
    <p>
      <strong style="color: rgb(51, 51, 51)">b.&nbsp;</strong
      ><span style="color: rgb(51, 51, 51)"
        >Screenshot of the e-mail or dispute received including </span
      ><span style="color: rgb(255, 0, 0)">name</span
      ><span style="color: rgb(51, 51, 51)">, </span
      ><span style="color: rgb(255, 0, 0)">date and content.</span
      ><span style="color: rgb(51, 51, 51)"
        >&nbsp;In other words, a customer has sent the complaint (Through PayPal
        Dispute or other Gateway, e-mail, etc.).</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >c. You could open a dispute through ticket or directly contact with
        iYoowe customer service. We will solve the issue with in one working
        day.</span
      >
    </p>
    <p><span style="color: rgb(255, 0, 0)">&nbsp;</span></p>
    <h3>
      <strong style="color: rgb(51, 51, 51)"
        >2.&nbsp;Orders not Received.</strong
      >
    </h3>
    <h3>
      <span style="color: rgb(102, 102, 102)"
        >iYoowe will not deal with the refund or resend if the tracking
        information shows the order is delivered.</span
      >
    </h3>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >a.&nbsp;If Your clients do not receive the package, a non-delivery
        certification issued by the local post office with an official seal is
        necessary.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >b.&nbsp;Tracking information alert. The tracking information shows
        Alert, the reasons are listed as below:</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >a)&nbsp;Incorrect/insufficient address.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)">b)&nbsp;No such number.</span>
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)">c)&nbsp;Unknown recipient.</span>
    </p>
    <p><span style="color: rgb(102, 102, 102)">d)&nbsp;Refused.</span></p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >e)&nbsp;Do not pick up in time.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >f)&nbsp;No safe delivery location.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)">g)&nbsp;Uncleared customs.</span>
    </p>
    <p><span style="color: rgb(102, 102, 102)">h)&nbsp;Others.</span></p>
    <p><span style="color: rgb(51, 51, 51)">Notes:</span></p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >a.&nbsp;The local distributor will deliver 1-3 times according to the
        actual situation. If it is still unclaimed during the delivery period,
        it will be returned to the local post office for storage in 3-7 days.
        Meanwhile, Your clients need to pick up the package by themselves.
        Otherwise, the product will be returned to the sender, our carrier.
        During the return, iYoowe takes no responsibility if products have been
        lost.&nbsp;iYoowe cannot offer a refund or resend if you don’t process
        the undelivered orders or packages destroyed by logistics
        companies.</span
      >
    </p>
    <p><span style="color: rgb(102, 102, 102)">&nbsp;</span></p>
    <h3>
      <strong style="color: rgb(51, 51, 51)">3. Products Damaged.</strong>
    </h3>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >iYoowe offers a full refund or a replacement if packages arrived are
        badly damaged.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >iYoowe offers a partial refund or a replacement if packages arrived
        partially damaged (</span
      ><span style="color: rgb(51, 51, 51)"
        >except thread, slightly wrinkled, small scratches</span
      ><span style="color: rgb(102, 102, 102)">&nbsp;etc.).</span>
    </p>
    <p><span style="color: rgb(51, 51, 51)">Notes:</span></p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >a.&nbsp;For fragile products, a refund is highly recommended.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)">b.&nbsp;For&nbsp;</span
      ><span style="color: rgb(51, 51, 51)">damaged packing boxes</span
      ><span style="color: rgb(102, 102, 102)">, iYoowe&nbsp;</span
      ><span style="color: rgb(51, 51, 51)">cannot</span
      ><span style="color: rgb(102, 102, 102)"
        >&nbsp;offer any refunds or other after-sale services due to the
        long-distant international delivery.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >c.&nbsp;For ordinary products,&nbsp;</span
      ><span style="color: rgb(51, 51, 51)">Your clients</span
      ><span style="color: rgb(102, 102, 102)"
        >&nbsp;shall complain or open a dispute with you&nbsp;</span
      ><span style="color: rgb(51, 51, 51)">within 5 days</span
      ><span style="color: rgb(102, 102, 102)"
        >&nbsp;after packages are delivered.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >d.&nbsp;For electronic products,&nbsp;</span
      ><span style="color: rgb(51, 51, 51)">Your clients</span
      ><span style="color: rgb(102, 102, 102)"
        >&nbsp;shall complain or open a dispute to you&nbsp;</span
      ><span style="color: rgb(51, 51, 51)">within 7 days</span
      ><span style="color: rgb(102, 102, 102)"
        >&nbsp;after packages are delivered.</span
      >
    </p>
    <p><span style="color: rgb(102, 102, 102)">&nbsp;</span></p>
    <!-- <p><span style="color: rgb(102, 102, 102)">&nbsp;</span></p> -->
    <h3>
      <strong style="color: rgb(51, 51, 51)"
        >4.&nbsp;Unacceptable Disputes.
      </strong>
    </h3>
    <p><span style="color: rgb(102, 102, 102)">Force Majeure</span></p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >iYoowe takes no responsibility for any product damaged or shipping
        delay caused by the act of god, including but not limited: epidemic
        situation, international situation, strike, war, earthquake, flood,
        virus, storm, heavy snow, customs inspection. However, iYoowe will
        notify you by iYoowe Chat, Skype, Email, Line, WhatsApp etc.</span
      >
    </p>
    <p>&nbsp;</p>
    <p>Other&nbsp;reasons</p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >The following reasons for refund or return iYoowe may not accept,
        including but not limited to:</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >a.&nbsp;The buyer does not like it.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >b.&nbsp;The product description is not real.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >c.&nbsp;Products smell unusual.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >d.&nbsp;The buyer ordered the wrong items or SKU.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >e.&nbsp;The shipping address was provided incorrectly.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >f.&nbsp;Product difference was negotiated in advance.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >g.&nbsp;Tracking&nbsp;information&nbsp;deleted&nbsp;by&nbsp;logistics&nbsp;companies&nbsp;or&nbsp;local&nbsp;post&nbsp;offices.</span
      >
    </p>
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  name: "refund",
};
</script>

<style lang="scss" scoped>
.refund {
  width: 1200px;
  margin: 0 auto;
  padding-top: 2vh;
  font-size: 18px;
  line-height: 40px;
  a {
    text-decoration: none;
  }
}
</style>