<template>
  <layout class="ubuntu">
    <transition name="el-fade-in" appear mode="out-in">
      <router-view></router-view>
    </transition>
  </layout>
</template>

<script>
import layout from './layout/index.vue';

export default {
  components: {
    layout,
  },
};
</script>
