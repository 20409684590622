import { parseDomain, ParseResultType } from 'parse-domain';
import { trigger } from '../event';

/**
 * 获取根域名
 * @returns {String}
 * @author Verdient。
 */
export function rootDomain() {
  const parseResult = parseDomain(window.document.domain);
  if (parseResult.type === ParseResultType.Listed) {
    const { domain, topLevelDomains } = parseResult;
    return domain + '.' + topLevelDomains.join('.');
  }
  return window.document.domain;
}

/**
 * 跳转到App
 * @param {String} path
 * @param {String} event 事件
 * @returns {String}
 * @author Verdient。
 */
export function gotoApp(path, event) {
  if (event) {
    trigger(event);
  }
  if (path) {
    if (path.substring(0, 1) === '/') {
      path = path.substring(1);
    }
    path = process.env.VUE_APP_APP_HOST + path;
  } else {
    path = process.env.VUE_APP_APP_HOST;
  }
  let url = new URL(location.href);
  if (url.search) {
    let url2 = new URL(path);
    for (let param of url.searchParams.entries()) {
      url2.searchParams.append(param[0], param[1]);
    }
    window.open(url2.toString());
  } else {
    window.open(path);
  }
}

export function gotoInstagram() {
  window.open('https://instagram.com/iyoowe?igshid=YmMyMTA2M2Y=');
}

export function gotoFacebook() {
  window.open('https://www.facebook.com/profile.php?id=100080843620068');
}

export function gotoLinkedIn() {
  window.open('https://www.linkedin.com/in/iyoowe');
}

export function gotoYouTube() {
  window.open('https://www.youtube.com/channel/UCp_ghHz00NNIZDhxolQAXIw');
}

export function gotoWhatsApp() {
  window.open('https://wa.me/+19093533330');
}
