<template>
  <div class="container w-1200px m-auto">
    <div class="cate-eat">
      <el-carousel height="283px">
        <el-carousel-item>
          <img src="../../assets/img/blog/bg1.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <img src="../../assets/img/blog/banner4.svg" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div>
      <div class="tabls">
        <ul>
          <li
            @click="handleTabClick('latestBlogs')"
            :class="{ show_tabs: type == 'latestBlogs' }"
          >
            Latest Blogs
          </li>
          <li
            @click="handleTabClick('dropshippingWorkshop')"
            :class="{ show_tabs: type == 'dropshippingWorkshop' }"
          >
            Dropshipping Workshop
          </li>
          <li
            @click="handleTabClick('productIdeas')"
            :class="{ show_tabs: type == 'productIdeas' }"
          >
            Product ideas
          </li>
          <li
            @click="handleTabClick('iYooweStories')"
            :class="{ show_tabs: type == 'iYooweStories' }"
          >
            iYoowe Stories
          </li>
        </ul>
      </div>
      <el-row :gutter="36" class="mt-24px mb-36px">
        <el-col :span="16">
          <div class="ml-3px mb-12px" v-if="tips">
            {{ tips }}
          </div>
          <el-card
            style="border-top-width: 3px; border-top-color: #30178b"
            v-loading="isListLoading"
          >
            <div class="p-18px">
              <div>
                <p
                  v-if="column_list.count == 0"
                  style="font-size: 23px; color: #979797"
                >
                  No data was detected
                </p>
                <div v-for="item in column_list.rows" :key="item.blog_id">
                  <el-row :gutter="16">
                    <el-col :span="6">
                      <img
                        class="w-full h-100px object-cover rounded-8px"
                        :src="item.image"
                      />
                    </el-col>
                    <el-col :span="18">
                      <div
                        class="text-xl overflow-ellipsis whitespace-nowrap overflow-hidden cursor-pointer hover:underline"
                        @click="toDetail(item.blog_id)"
                      >
                        {{ item.subject }}
                      </div>
                      <div class="flex items-center text-sm font-light my-8px">
                        <div class="font-normal">{{ item.author }}</div>
                        <div class="ml-8px">
                          {{ $formatDate(item.created_at) }}
                        </div>
                      </div>
                      <div>
                        <el-tag
                          class="mr-6px"
                          effect="plain"
                          size="tiny"
                          type="info"
                          v-for="(item, index) in item.tags"
                          :key="index"
                        >
                          {{ item }}
                        </el-tag>
                      </div>
                    </el-col>
                  </el-row>
                  <el-divider></el-divider>
                </div>
              </div>
              <div class="flex justify-end">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="page"
                  :page-sizes="[5, 10, 20, 50, 100]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="column_list.count"
                >
                </el-pagination>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <div
            class="flex items-center rounded-50px px-20px"
            style="
              border-style: solid;
              border-width: 3px;
              border-color: #6c5ad2;
            "
          >
            <input
              class="flex-auto h-50px focus:outline-none bg-transparent text-lg"
              v-model="subject"
              @keyup.enter="search"
            />
            <div @click="search">
              <icon
                :icon="
                  this.isSearchLoading
                    ? 'eos-icons:bubble-loading'
                    : 'akar-icons:search'
                "
                width="22"
                class="ml-6px cursor-pointer mt-5px"
              />
            </div>
          </div>
          <el-card class="mt-18px">
            <div
              class="text-2xl h-70px text-[#ffffff] flex items-center px-18px -mx-20px -mt-20px font-bold mb-20px"
              style="background: linear-gradient(45deg, #b6a0f6, #7576f0)"
            >
              Recommended Blogs
            </div>
            <div class="pb-8px">
              <div v-for="(item, index) in recommend_blog" :key="item.blog_id">
                <div>
                  <el-row :gutter="12">
                    <el-col :span="6">
                      <img
                        class="w-full h-50px object-cover rounded-2px"
                        :src="item.image"
                      />
                    </el-col>
                    <el-col :span="18">
                      <div
                        class="text-normal overflow-ellipsis whitespace-nowrap overflow-hidden cursor-pointer hover:underline"
                        @click="toDetail(item.blog_id)"
                      >
                        {{ item.subject }}
                      </div>
                      <div class="flex font-light mt-4px text-sm">
                        <div>{{ item.author }}</div>
                        <div class="ml-6px">
                          {{ $formatDate(item.created_at) }}
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                  <el-divider
                    class="!my-16px"
                    v-if="index < recommend_blog.length - 1"
                  ></el-divider>
                </div>
                <div
                  class="split-recommend"
                  v-if="index !== recommend_blog.length - 1"
                ></div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Recommended from './component/recommend.vue';
import everyList from './component/list.vue';
import { Icon } from '@iconify/vue2';

export default {
  name: 'blogMain',
  props: {
    defaultType: [String, Number],
  },
  components: {
    everyList,
    Recommended,
    Icon,
  },
  data() {
    return {
      isListLoading: false,
      isSearchLoading: false,
      subject: '',
      page: 1,
      pageSize: 5,
      column_list: {},
      type: this.defaultType || 'latestBlogs',
      recommend_blog: {},
      tips: '',
      num: 1,
    };
  },
  created() {
    this.list();
    this.getRecommedList();
  },
  methods: {
    getType() {
      switch (this.type) {
        case 'latestBlogs':
          return null;
        case 'dropshippingWorkshop':
          return 1;
        case 'productIdeas':
          return 2;
        case 'iYooweStories':
          return 3;
        default:
          return null;
      }
    },
    switchTab(type) {
      if (this.type != type) {
        this.type = type || 'latestBlogs';
        if (type) {
          this.$router
            .replace({
              name: 'blogWithType',
              params: {
                type,
              },
            })
            .catch((_) => _);
        } else {
          this.$router.replace({ name: 'blog' }).catch((_) => _);
        }
      }
    },
    handleTabClick(type) {
      if (this.type != type) {
        this.subject = '';
        this.page = 1;
        this.pageSize = 5;
        this.switchTab(type);
        this.list();
      }
    },
    list() {
      this.isListLoading = true;
      this.tips = '';
      let params = {
        type: this.getType(),
        subject: this.subject || undefined,
        page: this.page,
        page_size: this.pageSize,
      };

      this.$request
        .get('blog/list', params)
        .then((res) => {
          this.column_list = res.data;
        })
        .catch((e) => {
          this.$message.error(e.message);
        })
        .finally(() => {
          this.isListLoading = false;
          this.isSearchLoading = false;
          if (this.subject) {
            this.tips = 'Search results for ' + this.subject;
          }
        });
    },
    getRecommedList() {
      this.$request
        .get('blog/recommend')
        .then((res) => {
          this.recommend_blog = res.data;
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
    search() {
      this.page = 1;
      this.pageSize = 5;
      this.isSearchLoading = true;
      this.isSearch = true;
      this.switchTab();
      this.list();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.list();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.list();
    },
    toDetail(id) {
      this.$router.push({ name: 'blogDetail', params: { id } }).catch((_) => _);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  ul {
    li {
      list-style: none;
    }
  }
  .cate-eat {
    position: relative;
    width: 1200px;
    height: 283px;
    margin: 0 auto 34px;
    background: #fff;
    z-index: -1;
  }
  .tabls {
    width: 100%;
    height: 76px;
    background: rgba(107, 98, 185, 0.03);
    ul {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 1200px;
      height: 76px;
      margin: 0 auto;
      .show_tabs {
        background: #37108b;
        color: #fff !important;
      }
      li {
        padding: 15px 31px;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 600;
        color: #000000;
        cursor: pointer;
        &:hover {
          color: #6b62b9;
        }
      }
    }
  }
}
</style>
