import axios from 'axios';

/**
 * 创建axios实例
 * @returns {AxiosInstance}
 * @author Verdient。
 */
const createAxios = () => {
  let request = axios.create();
  request.interceptors.response.use(
    (res) => {
      const { code, message } = res.data;
      if (code >= 200 && code < 300) {
        return res.data;
      }
      return Promise.reject(new Error(message || 'Unknown Error'));
    },
    (error) => {
      if (error.response) {
        let response = error.response;
        if (response.status == 401) {
          logout();
          window.location.reload();
        }
        if (typeof response.data === 'object') {
          let data = response.data;
          if (data.message) {
            return Promise.reject(
              new Error(response.status + ' ' + data.message)
            );
          }
        }
      }
      return Promise.reject(error);
    }
  );
  return request;
};

/**
 * 请求
 * @param {Object} options 选项
 * @returns {AxiosInstance}
 * @author Verdient。
 */
export function request(options) {
  const defaultOptions = {
    timeout: 60000,
    baseURL: process.env.VUE_APP_API_HOST
  };
  let axios = createAxios();
  return axios(Object.assign(defaultOptions, options));
}

/**
 * GET请求
 * @param {String} url 请求地址
 * @param {Object} params 参数
 * @param {Object} options 选项
 * @returns {AxiosInstance}
 * @author Verdient。
 */
export function get(url, params, options = {}) {
  options.url = url;
  options.method = 'get';
  if (params) {
    options.params = params;
  }
  return request(options);
}

/**
 * POST请求
 * @param {String} url 请求地址
 * @param {Object} data 参数
 * @param {Object} options 选项
 * @returns {AxiosInstance}
 * @author Verdient。
 */
export function post(url, data, options = {}) {
  options.url = url;
  options.method = 'post';
  options.headers = {
    'Content-Type': 'application/json',
  };
  if (data) {
    options.data = data;
  }
  return request(options);
}

export default {
  install(Vue) {
    Vue.prototype.$request = request;
    Vue.prototype.$request.get = get;
    Vue.prototype.$request.post = post;
  },
};
