var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full sticky top-0 z-1024 bg-white px-2rem py-1.3rem",class:{ shadow: !_vm.isTop }},[_c('div',{staticClass:"flex items-center justify-between h-full w-1200px m-auto"},[_c('img',{staticClass:"w-140px cursor-pointer",attrs:{"src":"/svg/logo.svg"},on:{"click":function($event){return _vm.to('/')}}}),_c('div',{staticClass:"flex items-center"},[_c('navigator',{attrs:{"menus":[
          {
            label: 'Features',
            childrens: [
              { label: 'Wholesale', path: '/wholesale' },
              { label: 'Sourcing', path: '/sourcing' },
              { label: 'POD', path: '/pod' } ],
          },
          {
            label: 'Shipping',
            childrens: [
              { label: 'Estimated Calculation', path: '/shipping' },
              { label: 'Get Quotation', path: '/shipping/request-quote' } ],
          },
          {
            label: 'Blog',
            path: '/blog',
          },
          {
            label: 'Help Center',
            path: '/helpCenter',
          },
          {
            label: 'About Us',
            path: '/aboutUs',
          } ]}}),_c('div',{staticClass:"w-1px bg-[#cccccc] h-26px mx-24px"}),_c('div',{staticClass:"bg-[#37108b] px-16px py-8px text-white cursor-pointer rounded-3px hover:bg-[#240A5C] select-none",on:{"click":function($event){return _vm.gotoApp()}}},[_vm._v(" "+_vm._s(_vm.customerDisplayName ? 'Enter App' : 'JOIN IYOOWE NOW')+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }