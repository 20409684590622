<template>
  <div>
    <help-center-header @search="search" v-model="subject"></help-center-header>
    <div class="w-1200px m-auto mt-18px" v-loading="loading">
      <div class="ml-3px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/helpCenter' }"
            >Help Center</el-breadcrumb-item
          >
          <el-breadcrumb-item v-if="typePath" :to="{ path: typePath }">{{
            typeName
          }}</el-breadcrumb-item>
          <el-breadcrumb-item v-if="data.subject">{{
            data.subject
          }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <el-card
        class="mt-12px mb-24px px-60px py-24px"
        style="border-top-width: 3px; border-top-color: #37108b"
      >
        <iframe
          v-resize="{ log: false, checkOrigin: false }"
          :srcdoc="data.description"
          width="1080"
        ></iframe>
      </el-card>
    </div>
  </div>
</template>

<script>
import HelpCenterHeader from './components/help-center-header/index.vue';

export default {
  name: 'helpArticle',
  components: {
    HelpCenterHeader,
  },
  mounted() {
    this.fetch();
  },
  data() {
    return {
      subject: '',
      loading: false,
      data: {},
    };
  },
  computed: {
    typePath() {
      switch (this.data.type) {
        case 1:
          return '/helpCenter/iYooweFeatures';
        case 2:
          return '/helpCenter/authorizeStores';
        case 3:
          return '/helpCenter/myAccount';
        case 4:
          return '/helpCenter/productsAndOders';
        case 5:
          return '/helpCenter/shippingAndTracking';
        case 6:
          return '/helpCenter/paymentAndTaxes';
        case 7:
          return '/helpCenter/aftersales';
        default:
          return null;
      }
    },
    typeName() {
      switch (this.data.type) {
        case 1:
          return 'iYoowe Features';
        case 2:
          return 'Authorize Stores';
        case 3:
          return 'My Account';
        case 4:
          return 'Products & Oders';
        case 5:
          return 'Shipping & Tracking';
        case 6:
          return 'Payment & Taxes';
        case 7:
          return 'Aftersales';
        default:
          return null;
      }
    },
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$request
        .get('article/check', {
          article_id: this.$route.params.id,
        })
        .then((res) => {
          this.data = res.data;
        })
        .catch((e) => {
          this.$message.error(e.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      if (!this.subject) {
        return;
      }
      this.$router
        .push({
          name: 'helpCenter',
          params: {
            query: this.subject,
          },
        })
        .catch((_) => _);
    },
  },
};
</script>
