import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import admin from '@/utils/admin';
import elementUi from './plugin/element-ui';
import '@/assets/scss/common.scss';
import 'windi.css';
import store from './store';
import request from './plugin/request';
import iframeResize from 'iframe-resizer/js/iframeResizer';
import { Crisp } from "crisp-sdk-web";

Crisp.configure('a275e184-5549-4640-b6df-3eb40655baa4');

// import '@/fonts/font.css'

Vue.config.productionTip = false;
Vue.use(admin);
Vue.use(request);
Vue.use(elementUi);
Vue.directive('resize', {
  bind: function (el, { value = {} }) {
    el.addEventListener('load', () => iframeResize(value, el));
  },
  unbind: function (el) {
    el.iFrameResizer.removeListeners();
  },
});

new Vue({
  render: (h) => h(App),
  store,
  router,
}).$mount('#app');
