<template>
  <div class="container">
    <div class="this-list">
      <div>
        <el-image
          style="width: 195px; height: 118px"
          :src="column_item.image"
          :fit="column_item.image"
        ></el-image>
      </div>
      <div>
        <p>{{ column_item.subject }}</p>
        <div>
          <p>{{ column_item.author }}</p>
          <p>{{ $formatDate(column_item.created_at) }}</p>
        </div>
        <div>
          <p v-for="(item, index) in column_item.tags" :key="index">
            {{ item }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'list',
  props: ['column_item'],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  .this-list {
    display: flex;
    width: 650px;
    height: 118px;
    div:nth-child(1) {
      width: 195px;
      height: 118px;
      margin-right: 43px;
    }
  }
  .this-list > div:nth-of-type(2) {
    & > p:nth-child(1) {
      width: 417px;
      height: 52px;
      font-size: 20px;
      color: rgba($color: #000000, $alpha: 0.7);
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      &:hover {
        color: #37108b;
      }
    }
    & > div:nth-of-type(1) {
      display: flex;
      margin-top: 7px;
      width: 282px;
      height: 22px;
      font-size: 16px;
      font-weight: normal;
      color: #656565;
      line-height: 22px;
      & > p:nth-child(1) {
        margin-right: 75px;
      }
    }
    & > div:nth-of-type(2) {
      display: flex;
      margin-top: 12px;
      & > p {
        padding: 1px 6px;
        margin-right: 10px;
        border: 1px solid #d8d8d8;
        border-radius: 4px;
        color: #8c8c8c;
      }
    }
  }
}
</style>
