<template>
  <div class="w-1200px m-auto">
    <el-carousel height="283px" class="rounded-6px">
      <el-carousel-item>
        <img src="../../assets/img/blog/bg1.png" alt="" />
      </el-carousel-item>
      <el-carousel-item>
        <img src="../../assets/img/blog/banner4.svg" alt="" />
      </el-carousel-item>
    </el-carousel>
    <el-breadcrumb separator="/" class="mt-36px">
      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/blog' }">Blog</el-breadcrumb-item>
      <el-breadcrumb-item v-if="typePath" :to="{ path: typePath }">{{
        typeName
      }}</el-breadcrumb-item>
      <el-breadcrumb-item v-if="data.subject">{{
        data.subject
      }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="mt-12px bg-[#37108b] h-3px w-full"></div>
    <div class="w-800px m-auto">
      <div class="font-500 text-3xl mt-48px text-center">
        {{ data.subject }}
      </div>
      <div class="mt-12px text-sm text-center m-auto" v-if="data.created_at">
        {{ $formatDateTime(data.created_at) }}
      </div>
      <iframe
        class="mt-12px my-24px"
        v-resize="{ log: false, checkOrigin: false }"
        :srcdoc="data.description"
        width="800"
      ></iframe>
    </div>
  </div>
</template>

<script>
import Recommended from './component/recommend.vue';
export default {
  name: 'blogDetail',
  components: {
    Recommended,
  },
  data() {
    return {
      isLoading: false,
      data: {},
    };
  },
  created() {
    this.fetch();
  },
  computed: {
    typePath() {
      switch (this.data.type) {
        case 1:
          return '/blog/dropshippingWorkshop';
        case 2:
          return '/blog/productIdeas';
        case 3:
          return '/blog/iYooweStories';
        default:
          return null;
      }
    },
    typeName() {
      switch (this.data.type) {
        case 1:
          return 'Dropshipping Workshop';
        case 2:
          return 'Product ideas';
        case 3:
          return 'iYoowe Stories';
        default:
          return null;
      }
    },
  },
  methods: {
    fetch() {
      this.isLoading = true;
      this.$request
        .get('blog/check', {
          blog_id: this.$route.params.id,
        })
        .then((res) => {
          this.data = res.data;
        })
        .catch((e) => {
          this.$message.error(e.message);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
