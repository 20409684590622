<template>
  <div class="recommend">
    <div>
      <el-image
        style="width: 89px; height: 54px"
        :src="recommend_blog.image"
      ></el-image>
    </div>
    <div>
      <p>{{ recommend_blog.subject }}</p>
      <div>
        <p>{{ recommend_blog.author }}</p>
        <p>{{ $formatDate(recommend_blog.created_at) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'recommend',
  props: ['recommend_blog'],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.recommend {
  display: flex;
  justify-content: space-between;
  width: 306px;
  height: 56px;
  & > div:nth-of-type(2) {
    width: 196px;
    & > p:nth-child(1) {
      height: 34px;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    & > div:nth-of-type(1) {
      display: flex;
      height: 16px;
      margin-top: 8px;
      font-size: 12px;
      font-weight: normal;
      color: #656565;
      line-height: 16px;
      & > p:nth-of-type(2) {
        margin-left: 30px;
      }
    }
  }
}
</style>
