<template>
  <div class="boder">
    <div class="sourcr_everything">
      We source everything and ship anywhere for you
      <img src="../../assets/img/source/shoushi.png" alt="" />
    </div>
    <div class="free_price">
      <div><img src="../../assets/img/source/dagougou.png" alt="" /></div>
      <div>Free Sourcing & Fast Delivery</div>
      <div><img src="../../assets/img/source/dagougou.png" alt="" /></div>
      <div>Lower Price & Higher Quality</div>
      <div><img src="../../assets/img/source/dagougou.png" alt="" /></div>
      <div>All Categories & Numerous Suppliers</div>
    </div>
    <div class="source_product">
      <div>
        <p>Sourcing with Products in Your Store</p>
        <p>
          Encountered problems with your current suppliers? We provide solutions
          to:
        </p>
        <div>
          <img src="../../assets/img/source/yijiejue-2.png" alt="" />
          <p>High product price</p>
        </div>
        <div>
          <img src="../../assets/img/source/yijiejue-2.png" alt="" />
          <p>Poor product quality</p>
        </div>
        <div>
          <img src="../../assets/img/source/yijiejue-2.png" alt="" />
          <p>Out-of-stock situation</p>
        </div>
        <div>
          <img src="../../assets/img/source/yijiejue-2.png" alt="" />
          <p><strong>. . .</strong></p>
        </div>
      </div>
      <div><img src="../../assets/img/source/tupian.png" alt="" /></div>
    </div>
    <div class="customization">
      <div>
        <img src="../../assets/img/source/tupian2.svg" alt="" />
      </div>
      <div>
        <p>Sourcing with Your Customization</p>
        <p>
          Saw something potential on the internet but have no clue where to find
          the supply? Just post sourcing requests with your detailed
          requirements, our experienced sourcing team will help you find,
          choose, and manage suppliers to bring your products to the market.
        </p>
      </div>
    </div>
    <div class="AliExpress">
      <div>
        <p>Sourcing with AliExpress Products</p>
        <p>
          Found the product on AliExpress that you wanna sell but hesitate about
          the high purchase price and long shipping time or even the
          authenticity of the supplier? Our AliExpress Sourcing service enables
          dropshippers to get a better alternative while having a simpler
          experience with store management.
        </p>
      </div>
      <div><img src="../../assets/img/source/2.png" alt="" /></div>
    </div>
    <div class="iYoowe_glance">
      <div>
        <div>iYoowe Sourcing at a glance</div>
        <div>
          <div>
            1000+
            <p>Approved Suppliers</p>
          </div>
          <div>
            100,000+
            <p>Selected Products</p>
          </div>
          <div>
            36
            <p>Own Warehouses</p>
          </div>
          <div>
            100%
            <p>Customer Satisfaction</p>
          </div>
        </div>
      </div>
      <img src="../../assets/img/source/beijing1.svg" alt="" />
    </div>
    <div class="source_steps">
      <div>
        <div>Sourcing steps</div>
        <div>
          <img src="../../assets/img/source/bianzu21.png" alt="" />
        </div>
      </div>
      <div>
        <div>
          <div><img src="../../assets/img/source/4／1.png" alt="" /></div>
          <div>
            <p>Post Sourcing Request</p>
            <p>
              Post sourcing requests for any product you want and get results in
              1 to 3 business days.
            </p>
          </div>
        </div>
        <div>
          <div><img src="../../assets/img/source/bianzu7.png" alt="" /></div>
          <div>
            <p>Get Quote</p>
            <p>
              We offer the most competitive quotation for every sourcing
              request.
            </p>
          </div>
        </div>
        <div>
          <div><img src="../../assets/img/source/bianzu13.png" alt="" /></div>
          <div>
            <p>Quality Control</p>
            <p>Every product will get strictly inspected before shipping.</p>
          </div>
        </div>
        <div>
          <div><img src="../../assets/img/source/bianzu2.png" alt="" /></div>
          <div>
            <p>Fast Delivery</p>
            <p>
              Fast, easy, and affordable to door delivery, we ship effortlessly
              anytime anywhere.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'souring',
  created() {
    document.documentElement.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.sourcr_everything {
  position: relative;
  width: 1176px;
  height: 176px;
  margin: 50px auto 0;
  font-size: 69px;
  font-weight: 550;
  color: #000000;
  line-height: 79px;
  text-align: center;
  z-index: -1;
}
@keyframes shoushi {
  0% {
    right: 114px;
  }
  50% {
    right: 125px;
  }
  100% {
    right: 114px;
  }
}
.sourcr_everything img {
  position: absolute;
  top: 57px;
  right: 114px;
  width: 163px;
  height: 159px;
  animation: shoushi 1.5s linear 0s infinite;
}

.free_price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  height: 40px;
  margin: 45px auto 0;
}

.free_price > div:nth-of-type(2n + 1) {
  width: 29px;
  height: 20px;
  border: 1px dashed #979797;
}

.free_price > div:nth-of-type(2n) {
  width: 334px;
  height: 40px;
  font-size: 22px;
  font-weight: normal;
  color: #000000;
  line-height: 40px;
}

.free_price > div:nth-of-type(4) {
  width: 330px;
}

.free_price > div:nth-of-type(6) {
  width: 400px;
}

.source_product {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  height: 537px;
  margin: 103px auto 0;
}

.source_product > div:nth-child(1) > p:nth-of-type(1) {
  width: 670px;
  height: 71px;
  margin-top: 29px;
  font-size: 38px;
  font-weight: 550;
  color: #404040;
}

.source_product > div:nth-child(2) img {
  width: 560px;
  height: 537px;
  margin-left: -30px;
}
.source_product > div:nth-child(1) > p:nth-of-type(2) {
  width: 575px;
  height: 76px;
  font-size: 24px;
  color: #656565;
  line-height: 38px;
}

.source_product > div:nth-child(1) > div:nth-of-type(1) {
  margin-top: 42px;
}

.source_product > div:nth-child(1) > div {
  display: flex;
  align-items: center;
}

.source_product > div:nth-child(1) > div > p {
  margin-left: 36px;
  font-size: 24px;
  color: #404040;
}

.source_product > div:nth-child(1) > div:nth-of-type(n + 2) {
  margin-top: 25px;
}

.customization {
  display: flex;
  width: 1200px;
  margin: 30px auto 0;
}
.customization > div > img {
  width: 521px;
  height: 502px;
}
.customization > div:nth-of-type(2) > p:nth-of-type(1) {
  width: 624px;
  height: 70px;
  margin: 66px 0 37px 55px;
  font-size: 38px;
  font-weight: 550;
  color: #373737;
  line-height: 70px;
}
.customization > div:nth-of-type(2) > p:nth-of-type(2) {
  width: 586px;
  height: 353px;
  margin: 37px 0 0 55px;
  font-size: 24px;
  font-weight: normal;
  color: #404040;
  line-height: 39px;
}

.AliExpress {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 30px auto 0;
  overflow: hidden;
}
.AliExpress > div:nth-child(1) > p:nth-of-type(1) {
  width: 660px;
  height: 71px;
  margin-top: 41px;
  font-size: 39px;
  font-weight: 550;
  color: #404040;
}
.AliExpress > div:nth-child(1) > p:nth-of-type(2) {
  width: 576px;
  height: 246px;
  padding: 23px 0;
  font-size: 23px;
  font-weight: normal;
  color: #404040;
  line-height: 39px;
}

.iYoowe_glance {
  position: relative;
  width: 100%;
  height: 676px;
  margin-top: 177px;
  background: linear-gradient(225deg, #37108b 0%, #6b62b9 28%, #4f1169 100%);
  overflow: hidden;
  @keyframes ani_img {
    0% {
      transform: translate(-50%) rotate(0);
    }
    100% {
      transform: translate(-50%) rotate(360deg);
    }
  }
  & > img {
    position: absolute;
    left: 50%;
    top: -277px;
    transform: translate(-50%);
    width: 1160px;
    height: 1048px;
    z-index: 2;
    animation: ani_img 20s linear 0s infinite;
  }
}
.iYoowe_glance > div > div:nth-child(1) {
  position: relative;
  top: 95px;
  width: 1113px;
  height: 157px;
  margin: 0 auto;
  font-size: 60px;
  font-weight: 550;
  color: #ffffff;
  line-height: 157px;
  text-align: center;
}
.iYoowe_glance > div > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 146px auto 0;
}
.iYoowe_glance > div > div:nth-child(2) > div {
  position: relative;
  width: 237px;
  height: 154px;
  font-size: 50px;
  font-weight: normal;
  color: #ffffff;
  line-height: 154px;
  text-align: center;
}
.iYoowe_glance > div > div:nth-child(2) > div > p {
  position: absolute;
  top: 139px;
  left: 57px;
  width: 123px;
  height: 78px;
  font-size: 24px;
  font-weight: normal;
  color: #f1f1f1;
  line-height: 30px;
}
.source_steps {
  display: flex;
  width: 1200px;
  margin: 160px auto;
}
.source_steps > div:nth-child(1) > div:nth-child(1) {
  // width: 450px;
  height: 107px;
  margin-top: 208px;
  margin-right: 100px;
  font-size: 60px;
  font-weight: 550;
  color: #000000;
  line-height: 107px;
  text-align: right;
}
.source_steps > div:nth-child(1) > div:nth-child(2) {
  width: 450px;
  margin-top: 25px;
  text-align: right;
}
.source_steps > div:nth-child(2) > div {
  display: flex;
}
.source_steps > div:nth-child(2) > div:nth-of-type(n + 2) {
  margin-top: 18px;
}
.source_steps > div:nth-child(2) > div > div {
  height: 151px;
}
.source_steps > div:nth-child(2) > div > div:nth-child(1) {
  height: 130px;
  padding-top: 21px;
  margin-right: 61px;
}
.source_steps > div:nth-child(2) > div > div:nth-child(2) > p:nth-child(1) {
  width: 481px;
  height: 68px;
  font-size: 40px;
  font-weight: normal;
  color: #000000;
  line-height: 68px;
}
.source_steps > div:nth-child(2) > div > div:nth-child(2) > p:nth-child(2) {
  width: 481px;
  height: 78px;
  margin-top: 5px;
  font-size: 22px;
  color: #404040;
  line-height: 30px;
}
</style>
