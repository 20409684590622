<template>
  <div
    class="w-full sticky top-0 z-1024 bg-white px-2rem py-1.3rem"
    :class="{ shadow: !isTop }"
  >
    <div class="flex items-center justify-between h-full w-1200px m-auto">
      <img
        src="/svg/logo.svg"
        class="w-140px cursor-pointer"
        @click="to('/')"
      />
      <div class="flex items-center">
        <navigator
          :menus="[
            {
              label: 'Features',
              childrens: [
                { label: 'Wholesale', path: '/wholesale' },
                { label: 'Sourcing', path: '/sourcing' },
                { label: 'POD', path: '/pod' },
              ],
            },
            {
              label: 'Shipping',
              childrens: [
                { label: 'Estimated Calculation', path: '/shipping' },
                { label: 'Get Quotation', path: '/shipping/request-quote' },
              ],
            },
            {
              label: 'Blog',
              path: '/blog',
            },
            {
              label: 'Help Center',
              path: '/helpCenter',
            },
            {
              label: 'About Us',
              path: '/aboutUs',
            },
          ]"
        ></navigator>
        <div class="w-1px bg-[#cccccc] h-26px mx-24px"></div>
        <div
          class="bg-[#37108b] px-16px py-8px text-white cursor-pointer rounded-3px hover:bg-[#240A5C] select-none"
          @click="gotoApp()"
        >
          {{ customerDisplayName ? 'Enter App' : 'JOIN IYOOWE NOW' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { Icon } from '@iconify/vue2';
import { gotoApp } from '../../../utils/location';
import Navigator from './components/navigator.vue';
export default {
  name: 'headers',
  data: () => {
    return {
      customerDisplayName: Cookies.get('customer_display_name') || '',
      isTop: true,
    };
  },
  components: {
    Icon,
    Navigator,
  },
  computed: {
    defaultActive() {
      let path = this.$route.path.split('/').filter((v) => !!v);
      if (path.length > 0) {
        return '/' + path[0];
      }
      return '';
    },
  },
  created() {
    document.onscroll = () => {
      this.isTop = document.documentElement.scrollTop === 0;
    };
  },
  methods: {
    gotoApp,
    to(name) {
      this.$router.push(name).catch((_) => _);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-menu.el-menu--horizontal {
    border-bottom: none;
    .el-menu-item {
      height: 80px;
      line-height: 80px;
      padding: 0 36px;
    }
    .el-submenu__title {
      height: 80px;
      line-height: 80px;
      padding: 0 36px;
    }
    .el-menu--popup {
      .el-menu-item {
        height: 48px;
        line-height: 48px;
        padding-left: 24px;
      }
    }
  }
  .el-menu--popup-bottom-start {
    margin-top: 0px;
  }
}
</style>
