<template>
  <div class="flex text-normal text-[#595959] select-none libre-franklin">
    <div v-for="(menu, index) in menus2" :class="{ 'ml-32px': index > 0 }">
      <el-popover
        @show="showedSubmenuIndex = index"
        @after-leave="showedSubmenuIndex = -1"
        placement="bottom"
        trigger="hover"
        v-if="Array.isArray(menu.childrens)"
      >
        <div
          slot="reference"
          class="cursor-pointer hover:text-[#240A5C] flex items-center hover:font-bold antialiased"
          :class="{
            'font-bold': menu.isActive || showedSubmenuIndex == index,
            'text-[#240A5C]': menu.isActive || showedSubmenuIndex == index,
          }"
        >
          {{ menu.label }}
          <icon icon="dashicons:arrow-down-alt2" class="ml-4px"></icon>
        </div>
        <div class="text-1rem libre-franklin">
          <div
            class="px-12px cursor-pointer py-8px hover:text-[#240A5C] hover:font-bold menu-item"
            :title="children.label"
            :class="{
              'font-bold': children.isActive,
              'text-[#240A5C]': children.isActive,
            }"
            @click="to(children.path)"
            v-for="children in menu.childrens"
          >
            {{ children.label }}
          </div>
        </div>
      </el-popover>
      <div
        v-else
        class="cursor-pointer hover:text-[#240A5C] hover:font-bold antialiased menu-item"
        :class="{
          'font-bold': menu.isActive,
          'text-[#240A5C]': menu.isActive,
        }"
        :title="menu.label"
        @click="to(menu.path)"
      >
        {{ menu.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue2';
export default {
  name: 'navigator',
  props: {
    menus: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Icon,
  },
  data() {
    return {
      showedSubmenuIndex: -1,
    };
  },
  methods: {
    to(name) {
      this.$router.push(name).catch((_) => _);
    },
  },
  computed: {
    menus2() {
      let menus = [];
      for (let menu of JSON.parse(JSON.stringify(this.menus))) {
        if (Array.isArray(menu.childrens)) {
          menu.isActive = false;
          menu.isSubmenuShow = false;
          for (let children of menu.childrens) {
            if (
              (children.isActive =
                this.$route.path.substring(0, children.length) ===
                children.path)
            ) {
              menu.isActive = true;
            }
          }
        } else {
          menu.isActive =
            this.$route.path.substring(0, menu.path.length) === menu.path;
        }
        menus.push(menu);
      }
      return menus;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-item:before {
  display: block;
  content: attr(title);
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
</style>
