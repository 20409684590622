<template>
  <div class="privacy">
    <h1 style="text-align:center">
      <strong style="color: rgb(0, 0, 0)">Privacy policy of iYoowe</strong>
    </h1>
    <p class="firstLine">
      <span style="color: rgb(0, 0, 0)">Effective date: </span
      ><span style="color: rgb(255, 0, 0)">1st May 2022.</span>
    </p>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >This page informs you of our policies regarding the collection, use,
        and disclosure of personal data when you use our Service and the choices
        you have associated with that data. We use your data to provide and
        improve the Service. By using the Service, you agree to the collection
        and use of information in accordance with this policy. Unless otherwise
        defined in this Privacy Policy, terms used in this Privacy Policy have
        the same meanings as in our&nbsp;</span
      ><a
        href="https://www.inkthreadable.co.uk/terms"
        rel="noopener noreferrer"
        target="_blank"
        style="color: rgb(229, 85, 57)"
        >Term of Use</a
      ><span style="color: rgb(0, 0, 0)">.</span>
    </p>
    <h2><strong style="color: rgb(0, 0, 0)">Definitions</strong></h2>
    <h4><span style="color: rgb(0, 0, 0)" class="firstLine">Personal Data</span></h4>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >Personal Data is information about a living individual that can be used
        to identify the individual (either on its own or when this data when
        combined with other information&nbsp;either in our possession or likely
        to come into our possession).</span
      >
    </p>
    <h4><span style="color: rgb(0, 0, 0)" class="firstLine">Usage Data</span></h4>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >Usage Data is data collected automatically either generated by the use
        of the Service or from the Service infrastructure itself (for example,
        the duration of a page visit).</span
      >
    </p>
    <h4><span style="color: rgb(0, 0, 0)" class="firstLine">Cookies</span></h4>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >Cookies are small pieces of data stored on a User’s device that are
        used to remember details about the User.&nbsp;</span
      >
    </p>
    <h4><span style="color: rgb(0, 0, 0)" class="firstLine">Data Controller</span></h4>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >A Data Controller is a person who (either alone or jointly or in common
        with other persons) determines the purposes for which and the manner in
        which any personal data are, or are to be, processed. For the purpose of
        this Privacy Policy, we are a Data Controller of your data.</span
      >
    </p>
    <h4>
      <span style="color: rgb(0, 0, 0)" class="firstLine"
        >Data Processor (or Service Providers)</span
      >
    </h4>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >A Data Processor (or Service Provider) means any person (other than an
        employee of the Data Controller) who processes the data on behalf of the
        Data Controller. We may use the services of various Service Providers in
        order to process your data more effectively.</span
      >
    </p>
    <h4><span style="color: rgb(0, 0, 0)" class="firstLine">Data Subject</span></h4>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >A Data Subject is any living individual who is the subject of Personal
        Data.</span
      >
    </p>
    <h4><span style="color: rgb(0, 0, 0)" class="firstLine">User</span></h4>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >The User is the individual using our Service. The User corresponds to
        the Data Subject, who is the subject of Personal Data.</span
      >
    </p>
    <h2 class="paragraph">
      <span style="color: rgb(0, 0, 0)">Information Collection And Use</span>
    </h2>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >We collect several different types of information for various purposes
        to provide and improve our Service to you.</span
      >
    </p>
    <h3>
      <strong style="color: rgb(0, 0, 0)">Types of Data Collected</strong>
    </h3>
    <h4><span style="color: rgb(0, 0, 0)">Personal Data</span></h4>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you ("Personal Data"). Personally identifiable information may
        include, but is not limited to:</span
      >
    </p>
    <p class="firstLine"><span style="color: rgb(0, 0, 0)">Email address;</span></p>
    <p class="firstLine"><span style="color: rgb(0, 0, 0)">First name and last name;</span></p>
    <p class="firstLine"><span style="color: rgb(0, 0, 0)">Phone number;</span></p>
    <p class="firstLine">
      <span style="color: rgb(0, 0, 0)"
        >Address, State, Province, ZIP/Postal code, City; and</span
      >
    </p>
    <p class="firstLine"><span style="color: rgb(0, 0, 0)">Cookies and Usage Data.</span></p>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >We may use your Personal Data to contact you with newsletters,
        marketing or promotional materials and other information that may be of
        interest to you. You may opt out of receiving any, or all, of these
        communications from us by following the unsubscribe link or instructions
        provided in any email we send.</span
      >
    </p>
    <h4><span style="color: rgb(0, 0, 0)" class="firstLine">Usage Data</span></h4>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >We may also collect information about how the Service is accessed and
        used ("Usage Data"). This Usage Data may include information such as
        your computer's Internet Protocol address (IP address), browser type,
        browser version, the pages of our Service that you visit, the time and
        date of your visit, the time spent on those pages, unique device
        identifiers and other diagnostic data.</span
      >
    </p>
    <h4>
      <span style="color: rgb(0, 0, 0)" class="firstLine">Tracking &amp; Cookies Data</span>
    </h4>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >We use cookies and similar tracking technologies to track the activity
        on our Service and hold certain information.</span
      >
    </p>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >Cookies are files with small amount of data which may include an
        anonymous unique identifier. Cookies are sent to your browser from a
        website and stored on your device. Tracking technologies also used are
        beacons, tags, and scripts to collect and track information and to
        improve and analyse our Service.</span
      >
    </p>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. However, if you do not accept cookies, you may
        not be able to use some sections of our Service.</span
      >
    </p>
    <p><span style="color: rgb(0, 0, 0)">Examples of Cookies we use:</span></p>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >Session Cookies.&nbsp;We use Session Cookies to operate our
        Service.</span
      >
    </p>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >Preference Cookies.&nbsp;We use Preference Cookies to remember your
        preferences and various settings.</span
      >
    </p>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >Security Cookies.&nbsp;We use Security Cookies for security
        purposes.</span
      >
    </p>
    <p>
      <span style="color: rgb(0, 0, 0)"
        >We delete cookies afer 90 days.&nbsp;</span
      >
    </p>
    <h2><strong style="color: rgb(0, 0, 0)">Use of Data</strong></h2>
    <p>
      <span style="color: rgb(0, 0, 0)"
        >iYoowe Limited uses the collected data for various purposes:</span
      >
    </p>
    <p>
      <span style="color: rgb(0, 0, 0)"
        >·&nbsp;To provide and maintain our Service;</span
      >
    </p>
    <p>
      <span style="color: rgb(0, 0, 0)"
        >·&nbsp;To notify you about changes to our Service;</span
      >
    </p>
    <p>
      <span style="color: rgb(0, 0, 0)"
        >·&nbsp;To allow you to participate in interactive features of our
        Service when you choose to do so;</span
      >
    </p>
    <p>
      <span style="color: rgb(0, 0, 0)"
        >·&nbsp;To provide customer support;</span
      >
    </p>
    <p>
      <span style="color: rgb(0, 0, 0)"
        >·&nbsp;To gather analysis or valuable information so that we can
        improve our Service;</span
      >
    </p>
    <p>
      <span style="color: rgb(0, 0, 0)"
        >·&nbsp;To monitor the usage of our Service;</span
      >
    </p>
    <p>
      <span style="color: rgb(0, 0, 0)"
        >·&nbsp;To detect, prevent and address technical issues; and</span
      >
    </p>
    <p>
      <span style="color: rgb(0, 0, 0)"
        >·&nbsp;To provide you with news, special offers and general information
        about other goods, services and events which we offer that are similar
        to those that you have already purchased or enquired about, unless you
        have opted not to receive such information.</span
      >
    </p>
    <h2><strong style="color: rgb(0, 0, 0)">Retention of Data</strong></h2>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >iYoowe Limited will retain your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.</span
      >
    </p>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >iYoowe Limited will also retain Usage Data for internal analysis
        purposes. Usage Data is generally retained for a shorter period of time,
        except when this data is used to strengthen the&nbsp;security of or to
        improve the functionality of our Service, or we are legally obligated to
        retain this data for longer time periods.</span
      >
    </p>
    <h2><strong style="color: rgb(0, 0, 0)">Transfer Of Data</strong></h2>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >Your information, including Personal Data, may be transferred to — and
        maintained on — computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ than those from your jurisdiction.</span
      >
    </p>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >If you are located outside United Kingdom and choose to provide
        information to us, please note that we transfer the data, including
        Personal Data, to United Kingdom and process it there.</span
      >
    </p>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >Your consent to this Privacy Policy followed by your submission of such
        information represents your agreement to that transfer.</span
      >
    </p>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >iYoowe Limited will take all steps reasonably necessary to ensure that
        your data is treated securely and in accordance with this Privacy Policy
        and no transfer of your Personal Data will take place to an organisation
        or a country unless there are adequate controls in place including the
        security of your data and other personal information.</span
      >
    </p>
    <h2><strong style="color: rgb(0, 0, 0)">Disclosure Of Data</strong></h2>
    <h3>
      <span style="color: rgb(0, 0, 0)">Disclosure for Law Enforcement</span>
    </h3>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >Under certain circumstances, iYoowe may be required to disclose your
        Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government
        agency).&nbsp;</span
      >
    </p>
    <h3><strong style="color: rgb(0, 0, 0)">Legal Requirements</strong></h3>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >iYoowe Limited may disclose your Personal Data in the good faith belief
        that such action is necessary to:</span
      >
    </p>
    <p>
      <span style="color: rgb(0, 0, 0)"
        >·&nbsp;To comply with a legal obligation;</span
      >
    </p>
    <p>
      <span style="color: rgb(0, 0, 0)"
        >·&nbsp;To protect and defend the rights or property of iYoowe
        Limited;</span
      >
    </p>
    <p>
      <span style="color: rgb(0, 0, 0)"
        >·&nbsp;To prevent or investigate possible wrongdoing in connection with
        the Service;</span
      >
    </p>
    <p>
      <span style="color: rgb(0, 0, 0)"
        >·&nbsp;To protect the personal safety of users of the Service or the
        public; and</span
      >
    </p>
    <p>
      <span style="color: rgb(0, 0, 0)"
        >·&nbsp;To protect against legal liability.</span
      >
    </p>
    <h2><strong style="color: rgb(0, 0, 0)">Security Of Data</strong></h2>
    <p class="paragraph" >
      <span style="color: rgb(0, 0, 0)"
        >The security of your data is important to us, but be aware that no
        method of transmission over the Internet, or method of electronic
        storage is 100% secure. While we strive to use commercially acceptable
        means to protect your Personal Data, we cannot guarantee its absolute
        security.</span
      >
    </p>
    <h2><strong style="color: rgb(0, 0, 0)">Your Rights</strong></h2>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >iYoowe Limited aims to take reasonable steps to allow you to correct,
        amend, delete, or limit the use of your Personal Data.</span
      >
    </p>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >Whenever made possible, you can update your Personal Data directly
        within your account settings section. If you are unable to change your
        Personal Data, please contact us to make the required changes.</span
      >
    </p>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >If you wish to be informed what Personal Data we hold about you and if
        you want it to be removed from our systems, please contact our Data
        Protection Officer.</span
      >
    </p>
    <p>
      <span style="color: rgb(0, 0, 0)"
        >In certain circumstances, you have the right:</span
      >
    </p>
    <p>
      <span style="color: rgb(0, 0, 0)"
        >·&nbsp;To access and receive a copy of the Personal Data we hold about
        you;</span
      >
    </p>
    <p>
      <span style="color: rgb(0, 0, 0)"
        >·&nbsp;To rectify any Personal Data held about you that is inaccurate;
        and</span
      >
    </p>
    <p>
      <span style="color: rgb(0, 0, 0)"
        >·&nbsp;To request the deletion of Personal Data held about you.</span
      >
    </p>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >You have the right to data portability for the information you provide
        to iYoowe Limited. You can request to obtain a copy of your Personal
        Data in a commonly used electronic format so that you can manage and
        move it.</span
      >
    </p>
    <h2><strong style="color: rgb(0, 0, 0)">Links To Other Sites</strong></h2>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >Our Service may contain links to other sites that are not operated by
        us. If you click on a third party link, you will be directed to that
        third party's site.</span
      >
    </p>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >We strongly advise you to review the Privacy Policy of every site you
        visit.&nbsp;</span
      >
    </p>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >Some links on the iYoowe domain may be referral links, and iYoowe may
        receive commission if you make a purchase or register to a service using
        these links. Any commission will be taken from the standard rates paid,
        and is never charged to you.</span
      >
    </p>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or
        services.&nbsp;Liability for this content lies with the owner of the
        link’s domain, or the party indicated by the domain’s owner. Any
        information offered about an external link was correct at the time the
        link was added, but web pages can be changed at the discretion of the
        owner and they are under no obligation to inform iYoowe of these
        changes. For up to date information about the content of a link to an
        external domain, please check the relevant website’s privacy
        policy.&nbsp;</span
      >
    </p>
    <h2><strong style="color: rgb(0, 0, 0)">Children's Privacy</strong></h2>
    <p>
      <span style="color: rgb(0, 0, 0)"
        >Our Service does not address anyone under the age of 18
        ("Children").</span
      >
    </p>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >We do not knowingly collect personally identifiable information from
        anyone under the age of 18. If you are a parent or guardian and you are
        aware that your Children has provided us with Personal Data, please
        contact us. If we become aware that we have collected Personal Data from
        children without verification of parental consent, we take steps to
        remove that information from our servers.</span
      >
    </p>
    <h2>
      <strong style="color: rgb(0, 0, 0)"
        >Changes To This Privacy Policy</strong
      >
    </h2>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.</span
      >
    </p>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >We will let you know via email and/or a prominent notice on our
        Service, prior to the change becoming effective and update the
        "effective date" at the top of this Privacy Policy.</span
      >
    </p>
    <p class="paragraph">
      <span style="color: rgb(0, 0, 0)"
        >You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.</span
      >
    </p>
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  name: "privacy",
};
</script>

<style lang="scss" scoped>
.privacy {
  width: 1200px;
  margin: 0 auto;
  padding-top: 2vh;
  font-size: 18px;
  line-height: 40px;
  a {
    text-decoration: none;
  }
  // .firstLine {
  //   height: 40px;
  //   line-height: 30px;
  // }
  // .paragraph {
  //   line-height: 40px;
  // }
}
</style>