<template>
  <div class="terms">
    <h1 style="text-align: center"><strong>Term&nbsp;of&nbsp;Use</strong></h1>
    <h2><strong>Definition</strong></h2>
    <p>
      Terms not defined in this section shall have the meaning provided
      elsewhere in the Terms of Service.
    </p>
    <p>
      <strong style="color: rgb(0, 0, 0)">Agreement</strong
      ><span style="color: rgb(0, 0, 0)"
        >&nbsp;refers, collectively, to all the terms, conditions, notices
        contained or referenced in this document (the “Terms of Service,”) and
        all other operating rules, policies, including&nbsp;</span
      ><a
        href="https://printify.com/privacy-policy/"
        rel="noopener noreferrer"
        target="_blank"
        style="color: rgb(255, 0, 0)"
        >Privacy Policy</a
      ><span style="color: rgb(0, 0, 0)">&nbsp;and&nbsp;</span
      ><a
        href="https://printify.com/intellectual-property-policy/"
        rel="noopener noreferrer"
        target="_blank"
        style="color: rgb(255, 0, 0)"
        >Dispute Policy</a
      ><span style="color: rgb(0, 0, 0)"
        >, and procedures that we may publish from time to time on the
        Website.</span
      >
    </p>
    <p>
      <strong style="color: rgb(53, 58, 71)">Content </strong
      ><span style="color: rgb(53, 58, 71)"
        >refers to content featured or displayed through the Website, including
        without limitation text, data, articles, images, photographs, graphics,
        software, applications, designs, features, and other materials that are
        available on the Website or otherwise available through the
        Service.</span
      >
    </p>
    <p>
      <strong style="color: rgb(53, 58, 71)">Orders</strong
      ><span style="color: rgb(53, 58, 71)"
        >&nbsp;refer to the orders that you synchronize from your store or
        create manually.</span
      >
    </p>
    <p>
      <strong style="color: rgb(53, 58, 71)">Products</strong
      ><span style="color: rgb(53, 58, 71)"
        >&nbsp;refers to all products offered by iYoowe or other Providers on
        our Website. Including, but not limited to </span
      ><span style="color: rgb(255, 0, 0)"
        >clothing, accessories, home decor, kitchenware, footwear and
        stationary</span
      ><span style="color: rgb(53, 58, 71)">.</span>
    </p>
    <p>
      <strong style="color: rgb(53, 58, 71)">Us</strong
      ><span style="color: rgb(53, 58, 71)">&nbsp;and </span
      ><strong style="color: rgb(53, 58, 71)">We</strong
      ><span style="color: rgb(53, 58, 71)">&nbsp;refers to </span
      ><span style="color: rgb(255, 0, 0)">iYoowe. Inc</span
      ><span style="color: rgb(53, 58, 71)"
        >., as well as our directors, subsidiaries, contractors, licensors,
        officers, agents, and employees.</span
      >
    </p>
    <p>
      <strong style="color: rgb(53, 58, 71)">User</strong
      ><span style="color: rgb(53, 58, 71)">, </span
      ><strong style="color: rgb(53, 58, 71)">You</strong
      ><span style="color: rgb(53, 58, 71)">, </span
      ><strong style="color: rgb(53, 58, 71)">Your </strong
      ><span style="color: rgb(53, 58, 71)"
        >refers to the individual person, company, or organization that has
        visited or is using the Website or Service; that accesses or uses any
        part of the account; or that directs the use of the account in the
        performance of its functions.</span
      >
    </p>
    <p>
      <strong style="color: rgb(53, 58, 71)">Website</strong
      ><span style="color: rgb(53, 58, 71)"
        >&nbsp;refers to iYoowe website located at&nbsp;</span
      ><a
        href="https://printify.com/"
        rel="noopener noreferrer"
        target="_blank"
        style="color: rgb(255, 0, 0)"
        ><u>iYoowe.com</u></a
      ><span style="color: rgb(53, 58, 71)"
        >, and all content, services and products provided by iYoowe through the
        Website. It also includes iYoowe subdomains of&nbsp;</span
      ><u style="color: rgb(255, 0, 0)">iYoowe.com</u
      ><span style="color: rgb(255, 0, 0)">,</span
      ><span style="color: rgb(53, 58, 71)">&nbsp;</span>
    </p>
    <p><span style="color: rgb(102, 102, 102)">&nbsp;</span></p>
    <h2>
      <strong style="color: rgb(102, 102, 102)">General&nbsp;Provision</strong>
    </h2>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >1.&nbsp;You agree to comply with all applicable laws and regulations,
        agreements and policies when using the Website and services to access
        iYoowe&nbsp;or use iYoowe&nbsp;Services.</span
      >
    </p>
    <p><span style="color: rgb(102, 102, 102)">&nbsp;</span></p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >2.&nbsp;You have carefully read and agree to iYoowe's Privacy Policy,
        which governs the protection and usage of your personal information
        collected and stored by </span
      ><span style="color: rgb(255, 0, 0)">iYoowe&nbsp;Co., Ltd</span
      ><span style="color: rgb(102, 102, 102)"
        >. You agree to accept all terms of the </span
      ><span style="color: rgb(255, 0, 0)">Privacy Policy</span
      ><span style="color: rgb(102, 102, 102)">&nbsp;and agree that </span
      ><span style="color: rgb(255, 0, 0)">iYoowe&nbsp;</span
      ><span style="color: rgb(102, 102, 102)"
        >can access, use, disclose and share your personal information in
        accordance with the Privacy Policy.</span
      >
    </p>
    <p><br /></p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >3.&nbsp;All contents, materials and information you obtain from
        iYoowe&nbsp;website shall be used only for the purposes permitted by
        iYoowe&nbsp;website.
        Or&nbsp;we&nbsp;are&nbsp;not&nbsp;responsible&nbsp;for&nbsp;result&nbsp;of&nbsp;illegal
        purpose.</span
      >
    </p>
    <p><span style="color: rgb(102, 102, 102)">&nbsp;</span></p>
    <p><br /></p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >4.&nbsp;You are allowed to access the content, products or services
        provided by third-party websites through API, hyperlinks (text links,
        banners, channels or other forms), or other means. Please read the terms
        or privacy policies of such sites before using them. iYoowe&nbsp;neither
        has control over third-party websites nor monitors the websites.
        iYoowe&nbsp;takes no responsibility for any content, products, or
        services provided on such websites.</span
      >
    </p>
    <p><span style="color: rgb(102, 102, 102)">&nbsp;</span></p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >5.&nbsp;You shall not take any actions that may damage the integrity of
        computer systems or networks of iYoowe, or access to such computer
        systems or networks without authorization.</span
      >
    </p>
    <p><strong style="color: rgb(102, 102, 102)">&nbsp;</strong></p>
    <h2><strong style="color: rgb(102, 102, 102)">Account</strong></h2>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >To create an&nbsp;iYoowe&nbsp;Account You may be required to provide
        iYoowe with certain personal information, which may include your full
        legal name, address, phone number, email address, and payment
        information. This information will be held and used in accordance with </span
      ><a
        href="https://printify.com/privacy-policy/"
        rel="noopener noreferrer"
        target="_blank"
        style="color: rgb(102, 102, 102)"
        >Privacy Policy</a
      ><span style="color: rgb(102, 102, 102)"
        >. You agree that You will supply accurate and complete information to
        iYoowe&nbsp;that:</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >1. You shall not create an account or access the Service if You are
        under the age of 18</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >2. You shall not create an account using a false identity or
        information, or on behalf of someone other than yourself or your entity,
        which has provided you authorization to create the account on behalf of
        it.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >3. You shall not have an account or use the Service if You have been
        previously removed by the Company;</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >4. We may reject your registration or cancel an existing registration
        for any reason at our sole discretion.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >5. We will use the email address you provide as the primary method of
        communication.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >6. Any harassment or insult towards iYoowe&nbsp;employees may result in
        immediate account termination.</span
      >
    </p>
    <h2>
      <strong style="color: rgb(102, 102, 102)">User Responsibility</strong>
    </h2>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >You are solely responsible for keeping your account credentials secure
        and you may not disclose your credentials to any third party. You must
        immediately notify iYoowe&nbsp;if you have any reason to suspect that
        your credentials have been compromised, lost or stolen or in the case of
        any actual or suspected unauthorized use of your iYoowe&nbsp;Account.
      </span>
    </p>
    <p><span style="color: rgb(102, 102, 102)">&nbsp;</span></p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >You are solely responsible for any and all activities performed through
        your iYoowe&nbsp;account. iYoowe&nbsp;will not be liable for any loss
        that You may incur as a result of someone else using Your user&nbsp;name
        or password, either with or without Your knowledge. To the extent
        allowable by law, you shall be liable for any expenses and reasonable
        attorney’s fees for your failure to safeguard user and password
        information and/or promptly notify the Company about unauthorized use of
        your account or breach of your account information or password.</span
      >
    </p>
    <p><span style="color: rgb(102, 102, 102)">&nbsp;</span></p>
    <h2>
      <strong style="color: rgb(102, 102, 102)">iYoowe&nbsp;Service</strong>
    </h2>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >iYoowe&nbsp;also provides the following services
      </span>
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >1.&nbsp;Product&nbsp;service</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >iYoowe&nbsp;mall has various types of products with corresponding
        information including product attributes, descriptions, inventory,
        prices, etc. iYoowe&nbsp;reserves the right to change the product
        information on iYoowe&nbsp;at any time, such as changes in product
        description, attributes, inventory quantity, price and other
        information. The product information is subject to the real-time
        information displayed on iYoowe&nbsp;Website. Please read carefully
        before placing an order.</span
      >
    </p>
    <p><span style="color: rgb(102, 102, 102)">2. Sourcing Service</span></p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >You may submit a product Sourcing Request to iYoowe&nbsp;by providing
        relevant pictures, product links, text descriptions, etc.
        iYoowe&nbsp;will find a specific product for you within a certain period
        of time (usually 2 working&nbsp;days). You acknowledge and agree that,
        due to the inaccuracy or incompleteness of the product information
        provided by you, the sourcing result by iYoowe&nbsp;may differ from your
        specific requirements. The specific product information and condition
        shall be subject to the product you ordered.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >3. Order Processing&nbsp;service</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >Order synchronization: order information from your authorized store
        will be synchronized to iYoowe.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >Order Splitting: according to your actual transport needs, you can
        split an order into multiple orders for transportation.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >Order Combination: you can combine multiple orders into one order for
        transportation.</span
      >
    </p>
    <p><span style="color: rgb(102, 102, 102)">&nbsp;</span></p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >4.&nbsp;Store Management Services:</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >iYoowe&nbsp;offers store transfer service, multiple store management to
        meet the diversified needs of users, and you can manage multiple stores
        integrally on iYoowe&nbsp;platform.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >One-click Listing: you can list iYoowe&nbsp;products to your authorized
        stores. You are obliged to timely update the product information in the
        store since the product information of iYoowe&nbsp;platform may change
        at any time according to the actual situation. Meanwhile,
        iYoowe&nbsp;takes no responsibility for the resulting dispute.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >Product Connection: you can connect your products in the store with
        iYoowe&nbsp;products. Then, you are obliged to timely update the product
        information in the store since the product information of
        iYoowe&nbsp;platform will change according to the actual situation at
        any time. Meanwhile, iYoowe&nbsp;takes no responsibility for the
        resulting dispute.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >5.Order Fulfillment&nbsp;service</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >You can ship products to iYoowe&nbsp;and iYoowe&nbsp;will handle and
        ship your products.</span
      >
    </p>
    <p><span style="color: rgb(102, 102, 102)">6. Shipping Service</span></p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >iYoowe&nbsp;ships products for users with tracking information. Please
        refer to the following Shipping Service for details.</span
      >
    </p>
    <p><span style="color: rgb(102, 102, 102)">&nbsp;</span></p>
    <h2><strong style="color: rgb(102, 102, 102)">Privacy</strong></h2>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >iYoowe&nbsp;is committed to respecting user privacy and protecting the
        security of your personal information. iYoowe&nbsp;takes reasonable
        measures to protect your personal information and privacy. We promise
        that iYoowe&nbsp;only collects or uses your personal information when
        necessary for providing iYoowe&nbsp;Services, and such information will
        not be used for purposes other than providing services.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >Security Protection: A range of technologies and procedures are applied
        with strong security features to establish a sophisticated system and
        prevent unauthorized access, use, or disclosure of your personal
        information.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >Usage and Disclosure of Information: iYoowe&nbsp;reserves the right to
        obtain, use, store, and share your personal information upon explicit
        consent, and is subject to applicable laws and regulations. Your
        personal information or confidential content in our server will not be
        disclosed or altered without your authorization. You hereby agree and
        assure that iYoowe&nbsp;has the right to collect and use your
        information according to our Privacy Policy.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >You agree that iYoowe&nbsp;has the right to collect the information of
        your usage and behavior through cookies and other technologies, and we
        are free to use the pure desensitized commercial data that is no longer
        related to your personal information. If you prefer, of course, you can
        choose to delete cookies, but you will need to change user Settings each
        time you visit our site.
      </span>
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >In addition to complying with the Personal Information Protection and
        Privacy Policy specifically outlined in this Agreement, please carefully
        and fully read the promptly released Privacy Policy specifically written
        for the platform to protect your personal information.</span
      >
    </p>
    <p><br /></p>
    <h2>
      <a
        href="https://cjdropshipping.com/dispute-policy.html"
        rel="noopener noreferrer"
        target="_blank"
        style="color: rgb(102, 102, 102)"
        ><strong>Refund, Resend and Returns Policy</strong></a
      >
    </h2>
    <p>
      <a
        href="https://cjdropshipping.com/dispute-policy.html"
        rel="noopener noreferrer"
        target="_blank"
        style="color: rgb(102, 102, 102)"
        >Refund, Resend and Returns Policy</a
      ><span style="color: rgb(102, 102, 102)"
        >&nbsp;is an important basis for the resolution of disputes on iYoowe,
        and it applies to all dropshippers in cooperation with iYoowe. Please
        read the&nbsp;</span
      ><a
        href="https://cjdropshipping.com/dispute-policy.html"
        rel="noopener noreferrer"
        target="_blank"
        style="color: rgb(102, 102, 102)"
        >Refund, Resend and Returns Policy</a
      ><span style="color: rgb(102, 102, 102)"
        >&nbsp;carefully before registering in iYoowe platform and using iYoowe
        Services. All disputes shall be opened on iYoowe. If you directly open
        disputes on any other third-party platforms (including but not limited
        to third-party service providers or judicial authorities) without
        consultation with iYoowe, iYoowe has the right to permanently block your
        account.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >iYoowe will offer you a solution to resolve the dispute timely while
        the following evidence must be provided, including:</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >(1)&nbsp;Photos&nbsp;of the damaged items to display the damage;</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >(2)&nbsp;A&nbsp;video if photos&nbsp;are&nbsp;unable to prove the damage;</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >(3)&nbsp;Screenshots of the dispute via email, including the subject, date, and
        content of the email.</span
      >
    </p>
    <p><span style="color: rgb(102, 102, 102)">&nbsp;</span></p>
    <h2><strong style="color: rgb(102, 102, 102)">Force Majeure</strong></h2>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >Given the specific technical factors of Internet Services, you
        understand and agree that we shall not be liable for any loss (including
        but not limited to loss of property, income, data or other intangible
        loss) suffered by you under the following circumstances.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >iYoowe&nbsp;shall not bear any responsibility or pay any compensation
        of any nature for any loss caused by service or system failure,
        including but not limited to the following force majeure:</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >(1)&nbsp;Interruption of data transmission due to failure of
        communication terminal or telecommunications facility caused by force
        majeure.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >(2)&nbsp;System failures and malfunctions caused by force majeure
        events, including but not limited to typhoons, earthquakes, tsunamis,
        floods, power outages, fires, storms, wars, political unrest, labor
        strikes, labor or material shortages, riots, riots, civil strife,
        terrorist attack, explosion, natural disaster, government action, court
        orders of domestic and foreign courts.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >(3)&nbsp;A domestic or international epidemic, including but not
        limited to suspension or delay of service, or system failure caused by
        any third party, or any suspension or interruption of transportation or
        business operations.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >(4)&nbsp;Delay or interruption of the platform or service due to
        computer viruses, Trojan horses, other malicious programs, hacker
        attacks, technical adjustments or failures of telecommunications
        departments and network operating companies, system maintenance and
        other unexpected reasons.</span
      >
    </p>
    <p><br /></p>
    <h2>
      <strong style="color: rgb(102, 102, 102)"
        >Limitation of Liability.</strong
      >
    </h2>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >IN NO EVENT SHALL iYoowe, ITS OFFICER, DIRECTORS, AGENTS, AFFILIATES,
        EMPLOYEES, ADVERTISES, OR PROVIDERS BE LIABLE FOR ANY INDIRECT,
        INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES INCLUDING, BUT NOT LIMITED
        TO, LOSS OF USE, LOSS OF PROFITS, OR LOSS OF DATA, WHETHER IN AN ACTION
        IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE), EQUITY OR
        OTHERWISE, OR FOR DAMAGES IN&nbsp;THE AGGREGATE EXCEEDING THE AMOUNT OF
        THE FEES PAID ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF
        THIS SERVICE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
        LIMITATION OF LIABILITY, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO
        YOU.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >1.&nbsp;You agree to indemnify and hold iYoowe&nbsp;and, its parent,
        subsidiaries, affiliates, partners, and Providers, harmless from any
        claim or demand, including reasonable attorneys’ fees, made by any third
        party due to or arising out of your breach of these Terms of Service, or
        your violation of any law or the rights of a third party.</span
      >
    </p>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >2.&nbsp;Warranty Disclaimer. iYoowe&nbsp;controls and operates the
        Service from various locations and makes no representation that the
        Service is appropriate or available for use in all locations. Services
        may not be available in Your location or may vary across locations. THE
        SERVICE AND CONTENT ARE PROVIDED “AS IS”, “AS AVAILABLE” AND IS PROVIDED
        WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR
        IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE,
        NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE,
        AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF
        TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED, SAVE TO THE EXTENT
        REQUIRED BY LAW. iYoowe, AND ITS DIRECTORS, EMPLOYEES, AGENTS,
        REPRESENTATIVES, SUPPLIERS, PARTNERS AND PROVIDERS DO NOT WARRANT THAT:
        (A) THE SERVICE WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR
        LOCATION; (B) ANY DEFECTS OR ERRORS WILL BE CORRECTED; (C) ANY CONTENT
        OR SOFTWARE AVAILABLE AT OR THROUGH THE SERVICE IS FREE OF VIRUSES OR
        OTHER HARMFUL COMPONENTS; OR (D) THE RESULTS OF USING THE SERVICE WILL
        MEET YOUR REQUIREMENTS. YOUR USE OF THE SERVICE IS SOLELY AT YOUR OWN
        RISK. SOME STATES / COUNTRIES DO NOT ALLOW LIMITATIONS ON IMPLIED
        WARRANTIES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</span
      >
    </p>
    <p><span style="color: rgb(102, 102, 102)">&nbsp;</span></p>
    <h2><strong style="color: rgb(102, 102, 102)">Modification</strong></h2>
    <p>
      <span style="color: rgb(102, 102, 102)"
        >Any new features or tools added to the current website should also be
        subject to the User Agreement.&nbsp;You can view the latest version of
        the "User Agreement" on this page at any time.We reserve the right to
        update, modify or replace any part of this User Agreement by making
        announcements on iYoowe&nbsp;website.&nbsp;The user is responsible for
        regularly checking the changes to this page, and iYoowe&nbsp;will notify
        you of the revision of the terms by email or website announcement. Users
        accept any changes when they continue to use iYoowe&nbsp;Services and
        application or visit iYoowe&nbsp;after any changes have been
        published.</span
      >
    </p>
    <p><span style="color: rgb(102, 102, 102)">&nbsp;</span></p>
  </div>
</template>

<script>
export default {
  name: "terms",
};
</script>

<style lang="scss" scoped>
.terms {
  width: 1200px;
  margin: 0 auto;
  padding-top: 2vh;
  font-size: 18px;
  line-height: 40px;
  a {
    text-decoration: none;
  }
}
</style>