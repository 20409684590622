<template>
  <div class="contain">
    <div class="about_background">
      <div class="about_content">
        <div class="content_left">
          <p><strong>ABOUT US</strong></p>
          <p><strong>Unlock Your Supply Chain Visibility</strong></p>
          <p>
            We offer every service you need to build, scale and brand your
            business.
          </p>
        </div>
        <div class="about_logo">
          <img src="../../assets/img/about/bianzu9.png" alt="" />
        </div>
      </div>
    </div>
    <div class="flex justify-center font-bold text-4xl mt-48px">OUR VALUE</div>
    <div class="ourvalue_content">
      <div>
        <img src="../../assets/img/about/jieping.png" alt="" />
      </div>
      <div>
        <div>
          <p>1</p>
          <p>Quality supply achieves commercial value</p>
        </div>
        <div>
          <p>2</p>
          <p>Print-on-demand service delivers your brand value</p>
        </div>
        <div>
          <p>3</p>
          <p>Green pursuit presents sustainable value</p>
        </div>
        <div>
          <p>4</p>
          <p>i + You = we create a greater value</p>
        </div>
      </div>
    </div>
    <div class="our_mission">
      <p>OUR MISSION</p>
      <p>To be the Last Partner You’ll Ever Need</p>
      <p>
        We believe in a more open and integrated eCommerce ecosystem where
        retailers, dropshippers, and suppliers can work together with a better
        experience.
      </p>
    </div>
    <div class="join_iWoowe">
      <p>Join iYoowe for free</p>
      <p>Explore all services we offer to boost your sales!</p>
      <div @click="gotoApp('iyoowe-selected')">
        <div class="w-full flex items-center px-20px justify-center">
          <div class="text-white text-3xl">Start Today</div>
          <icon
            class="ml-8px"
            icon="charm:arrow-up-right"
            width="30"
            color="white"
          ></icon>
        </div>
      </div>
    </div>
    <div class="i_You_we">
      <div class="logo_group">
        <div><img src="/img/12.png" alt="" /></div>
        <div>
          <p>TEAMWORK MAKES THE DREAM WORK</p>
          <p>i + You = We</p>
        </div>
      </div>
      <div>
        iYoowe has a group of experienced people to provide professional
        services for you at any time.
      </div>
      <div>
        - Responsive Custom Service Team<br />
        - Productive Sourcing Team<br />
        - Efficient Logistic Solution Team
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue2';
import { gotoApp } from '../../utils/location';

export default {
  name: 'about',
  components: {
    Icon,
  },
  created() {
    document.documentElement.scrollTop = 0;
  },
  methods: {
    gotoApp,
  },
};
</script>

<style lang="scss" scoped>
.contain {
  width: 100%;
  height: 100%;
  .about_background {
    position: relative;
    width: 100%;
    height: 523px;
    background: #efeff7;
    z-index: -1;
  }

  .about_content {
    display: flex;
    width: 1200px;
    height: 100%;
    margin-left: 50%;
    transform: translate(-50%);
  }

  .content_left > p:nth-of-type(1) {
    width: 192px;
    height: 42px;
    margin-top: 102px;
    font-size: 24px;
    font-weight: normal;
    color: #333333;
    line-height: 42px;
  }

  .content_left > p:nth-of-type(2) {
    width: 736px;
    height: 140px;
    margin-top: -5px;
    font-size: 72px;
    color: #37108b;
    line-height: 82px;
  }

  .content_left > p:nth-of-type(3) {
    width: 508px;
    height: 99px;
    margin-top: 40px;
    font-size: 24px;
    font-weight: normal;
    color: #404040;
    line-height: 40px;
  }

  .about_logo {
    width: 570px;
    margin-left: -110px;
  }

  .about_logo img {
    width: 100%;
    height: 100%;
  }

  /* ourvalue_content */
  .ourvalue_content {
    display: flex;
    width: 1200px;
    margin-top: 60px;
    margin-left: 50%;
    transform: translate(-50%);
  }

  .ourvalue_content > div:nth-of-type(1) {
    position: relative;
    top: 60px;
    width: 522px;
    height: 347px;
  }

  .ourvalue_content > div:nth-of-type(1) img {
    width: 100%;
    height: 100%;
  }

  .ourvalue_content > div:nth-of-type(2) > div {
    display: flex;
  }

  .ourvalue_content > div:nth-of-type(2) > div > p:nth-of-type(1) {
    width: 37px;
    height: 104px;
    font-size: 84px;
    font-family: 'cinzel', sans-serif;
    color: #37108b;
    line-height: 104px;
  }

  .ourvalue_content > div:nth-of-type(2) > div > p:nth-of-type(2) {
    width: 415px;
    height: 74px;
    font-size: 32px;
    font-weight: normal;
    color: #404040;
    line-height: 37px;
  }
  .ourvalue_content
    > div:nth-of-type(2)
    > div:nth-of-type(1)
    > p:nth-of-type(1) {
    margin-left: 121px;
  }
  .ourvalue_content > div:nth-of-type(2) > div > p:nth-of-type(2) {
    position: relative;
    top: 32px;
    margin-left: 41px;
  }
  .ourvalue_content
    > div:nth-of-type(2)
    > div:nth-of-type(1)
    > p:nth-of-type(2) {
    top: 19px;
  }
  .ourvalue_content
    > div:nth-of-type(2)
    > div:nth-of-type(2)
    > p:nth-of-type(1) {
    margin-left: 227px;
    margin-top: 9px;
  }
  .ourvalue_content
    > div:nth-of-type(2)
    > div:nth-of-type(3)
    > p:nth-of-type(1) {
    margin-left: 82px;
    margin-top: 9px;
  }

  .ourvalue_content
    > div:nth-of-type(2)
    > div:nth-of-type(4)
    > p:nth-of-type(1) {
    margin-left: 194px;
    margin-top: 9px;
  }

  .our_mission {
    width: 1200px;
    height: 304px;
    margin-top: 117px;
    margin-left: 50%;
    transform: translate(-50%);
  }

  .our_mission > p:nth-of-type(1) {
    width: 1200px;
    height: 50px;
    font-size: 32px;
    font-weight: normal;
    color: #404040;
    text-align: center;
  }

  .our_mission > p:nth-of-type(2) {
    width: 1200px;
    height: 61px;
    margin-top: 3px;
    font-size: 48px;
    font-weight: normal;
    color: #404040;
    line-height: 55px;
    text-align: center;
  }

  .our_mission > p:nth-of-type(3) {
    width: 1040px;
    height: 166px;
    margin-top: 24px;
    margin-left: 80px;
    font-size: 33px;
    font-weight: normal;
    color: #656565;
    line-height: 39px;
    text-align: center;
  }

  .join_iWoowe {
    width: 100%;
    height: 422px;
    margin-top: 110px;
    background-image: url(../../assets/img/about/bianzu6.png);
    background-size: 100% 422px;
  }

  .join_iWoowe > p:nth-of-type(1) {
    position: relative;
    top: 81px;
    width: 903px;
    height: 77px;
    margin-left: 50%;
    transform: translate(-50%);
    font-size: 48px;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
  }

  .join_iWoowe > p:nth-of-type(2) {
    position: relative;
    top: 83px;
    width: 567px;
    height: 110px;
    margin-left: 50%;
    transform: translate(-50%);
    font-size: 32px;
    color: #ffffff;
    line-height: 37px;
    text-align: center;
  }

  .join_iWoowe > div:nth-of-type(1) {
    display: flex;
    align-items: center;
    position: relative;
    top: 93px;
    width: 300px;
    height: 70px;
    margin-top: 6px;
    margin-left: 50%;
    transform: translate(-50%);
    background: url(../../assets/img/about/juxing.png) no-repeat;
    background-size: 300px 70px;
    cursor: pointer;
    &:hover {
      top: 90px;
      transition: all 1s;
    }
    p {
      width: 154px;
      height: 28px;
      margin-left: 61px;
      font-size: 24px;
      font-weight: normal;
      color: #ffffff;
      line-height: 28px;
    }
    img {
      width: 33px;
      height: 33px;
      margin: 7px 0 0 -10px;
    }
  }

  .i_You_we {
    width: 1044px;
    height: 607px;
    padding: 0 0 0 23px;
    margin: 114px 0 140px 50%;
    transform: translate(-50%);
  }

  .logo_group {
    display: flex;
    height: 342px;
  }

  .logo_group > div:nth-of-type(1) {
    width: 379px;
    height: 342px;
  }

  .logo_group > div:nth-of-type(1) img {
    width: 100%;
    height: 100%;
  }

  .logo_group > div:nth-of-type(2) {
    flex: 1;
    height: 342px;
  }

  .logo_group > div:nth-of-type(2) > p:nth-of-type(1) {
    position: relative;
    top: 138px;
    left: -100px;
    width: 100%;
    height: 50px;
    font-size: 32px;
    font-weight: normal;
    color: #404040;
  }

  .logo_group > div:nth-of-type(2) > p:nth-of-type(2) {
    position: relative;
    top: 162px;
    width: 442px;
    height: 90px;
    font-size: 68px;
    font-weight: 600;
    color: #37108b;
  }

  .i_You_we > div:nth-of-type(2) {
    width: 933px;
    height: 80px;
    margin-left: 114px;
    font-size: 32px;
    font-weight: normal;
    color: #656565;
    text-align: center;
  }

  .i_You_we > div:nth-of-type(3) {
    width: 532px;
    height: 149px;
    margin-top: 36px;
    margin-left: 55%;
    transform: translate(-50%);
    font-size: 32px;
    font-weight: 600;
    color: #404040;
    line-height: 50px;
  }
}
</style>
