<template>
  <div class="boder">
    <div class="wholesale_bg">
      <div class="wholesale_bg_content">
        <div class="wholesale_bg_left">
          <p><strong>YOUR ONE-STOP SHOP FOR WHOLESALE</strong></p>
          <p>
            Scale up your business with iYoowe Wholesale Program. Shop over
            100,000 products, all in one place.
          </p>
          <p @click="showChat">Get Quotation</p>
        </div>
        <div class="wholesale_bg_img">
          <img src="../../assets/img/wholesale/office.png" alt="" />
        </div>
      </div>
    </div>
    <div class="iYoowe_advantage">
      <div class="iYoowe_advantage_content">
        <div>
          <div>
            <img src="../../assets/img/wholesale/MOQ.png" alt="" />
          </div>
          <p>Flexible MOQ</p>
          <p>
            A flexible MOQ can help entrepreneurs, especially start-ups, have a
            healthier cash flow, keeping inventory costs in check. Our sales
            team is dedicated to getting you the MOQ that suits your vision for
            your business.
          </p>
        </div>
        <div>
          <div>
            <img src="../../assets/img/wholesale/zhekoushangpin.png" alt="" />
          </div>
          <p>Unbeatable Discount</p>
          <p>
            With years of cultivation in the logistics field, we've built a
            strong network with thousands of trustworthy factories in China. The
            abundant resources of supply made it possible for us to offer you
            the bottom price.
          </p>
        </div>
        <div>
          <div>
            <img src="../../assets/img/wholesale/pay.png" alt="" />
          </div>
          <p>Buy First Pay Later</p>
          <p>
            Buy now, iYoowe enables you to make purchases first and pay them off
            over time in weekly, bi-weekly, or monthly installments. Contact us
            to get your own installment plan today
          </p>
        </div>
      </div>
    </div>
    <div class="categories">
      <div>
        <div>
          <img src="../../assets/img/wholesale/1.png" alt="" />
        </div>
        <div>
          <img src="../../assets/img/wholesale/2.png" alt="" />
        </div>
        <div>
          <img src="../../assets/img/wholesale/3.png" alt="" />
        </div>
      </div>
      <div>
        <div>
          <img src="../../assets/img/wholesale/4.png" alt="" />
        </div>
        <div>
          <img src="../../assets/img/wholesale/5.png" alt="" />
        </div>
        <div>
          <img src="../../assets/img/wholesale/6.png" alt="" />
        </div>
      </div>
      <div @click="gotoApp('iyoowe-selected')">
        <p class="text-22px">Sign Up to Gain Access to All Categories</p>
        <div>
          <img src="../../assets/img/wholesale/youjiantou.png" alt="" />
        </div>
      </div>
    </div>
    <div class="faqs">
      <img src="../../assets/img/wholesale/FAQs 2.png" alt="" />
    </div>
    <div class="iYoowe_program">
      <div>
        <div>
          <p>What is iYoowe Wholesale Program？</p>
          <p @click="expand(0)">
            <img src="../../assets/img/wholesale/jiahao.svg" alt="" />
          </p>
          <p @click="close(0)">
            <img src="../../assets/img/wholesale/jianhao.svg" alt="" />
          </p>
        </div>
        <p style="display: none">
          iYoowe Wholesale Program empowers business owners to purchase in bulk
          at the bottom price directly from the best manufacturers. This program
          is dedicated to helping our customers through effective communication,
          quality inspection and fast shipping.
        </p>
      </div>
      <div>
        <div>
          <p>Is iYoowe Wholesale Program free?</p>
          <p @click="expand(1)">
            <img src="../../assets/img/wholesale/jiahao.svg" alt="" />
          </p>
          <p @click="close(1)">
            <img src="../../assets/img/wholesale/jianhao.svg" alt="" />
          </p>
        </div>
        <p style="display: none">
          Yes, it's totally free for consultation and quotation. We provide free
          sourcing service for you to get a detailed and competitive quote in
          1-3 days.
        </p>
      </div>
      <div>
        <div>
          <p>How does buy first pay later work?</p>
          <p @click="expand(2)">
            <img src="../../assets/img/wholesale/jiahao.svg" alt="" />
          </p>
          <p @click="close(2)">
            <img src="../../assets/img/wholesale/jianhao.svg" alt="" />
          </p>
        </div>
        <p style="display: none">
          Once you join this program, you'll get an exclusive bulk discount for
          your orders. We support flexible MOQ while the bigger the order amount
          is, the more competitive discount you will get. Plus, iYoowe Shipping
          offers a cost-effective logistics solution, saving your cost from all
          aspects.
        </p>
      </div>
      <div>
        <div>
          <p>
            What are the pricing benefits I get when I join iYoowe Wholesale
            Program?
          </p>
          <p @click="expand(3)">
            <img src="../../assets/img/wholesale/jiahao.svg" alt="" />
          </p>
          <p @click="close(3)">
            <img src="../../assets/img/wholesale/jianhao.svg" alt="" />
          </p>
        </div>
        <p style="display: none">
          All products on iYoowe Selected, diligently chosen from our own supply
          partners, are available for wholesale. If you can't find the product
          you want in our marketplace, we can also help with finding a quality
          supplier for you.
        </p>
      </div>
      <div>
        <div>
          <p>What kind of products can I get with iYoowe Wholesale Program</p>
          <p @click="expand(4)">
            <img src="../../assets/img/wholesale/jiahao.svg" alt="" />
          </p>
          <p @click="close(4)">
            <img src="../../assets/img/wholesale/jianhao.svg" alt="" />
          </p>
        </div>
        <p style="display: none">
          iYoowe supports placing sample orders as we know it's important to
          test out the product before making up your mind to purchase in bulk.
          You can place a test order with your authorized store, or just simply
          go to our sales team and get the sample order directly.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { showChat } from "../../utils/chat";
import { gotoApp } from "../../utils/location";

export default {
  name: "wholesale",
  methods: {
    showChat,
    gotoApp,
    expand(num) {
      const p1s = document.querySelectorAll(
        ".iYoowe_program>div>div>p:nth-of-type(2)"
      );
      const p2s = document.querySelectorAll(
        ".iYoowe_program>div>div>p:nth-of-type(3)"
      );
      const ps = document.querySelectorAll(".iYoowe_program>div>p");
      for (var j = 0; j < ps.length; j++) {
        (function (j) {
          p1s[j].style.display = "block";
          p2s[j].style.display = "none";
          ps[j].style.display = "none";
        })(j);
        p1s[num].style.display = "none";
        p2s[num].style.display = "block";
        ps[num].style.display = "block";
      }
    },
    close(num) {
      const p1s = document.querySelectorAll(
        ".iYoowe_program>div>div>p:nth-of-type(2)"
      );
      const p2s = document.querySelectorAll(
        ".iYoowe_program>div>div>p:nth-of-type(3)"
      );
      const ps = document.querySelectorAll(".iYoowe_program>div>p");
      p1s[num].style.display = "block";
      p2s[num].style.display = "none";
      ps[num].style.display = "none";
    },
  },
};
</script>

<style lang="scss" scoped>
.wholesale_bg {
  width: 100%;
  height: 585px;
  background: #efeff7;
}

.wholesale_bg_content {
  display: flex;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.wholesale_bg_left {
  width: 786px;
  margin-top: 60px;
}

.wholesale_bg_left > p:nth-of-type(1) {
  width: 786px;
  height: 260px;
  padding: 20px 0;
  font-size: 72px;
  font-weight: normal;
  color: #37108b;
  line-height: 82px;
}

.wholesale_bg_left > p:nth-of-type(2) {
  width: 601px;
  height: 80px;
  font-size: 21px;
  font-weight: normal;
  color: #000000;
  line-height: 40px;
}

.wholesale_bg_left > p:nth-of-type(3) {
  width: 237px;
  height: 47px;
  margin-top: 25px;
  background: url(../../assets/img/wholesale/juxing1.png) no-repeat 100% 100%;
  font-size: 21px;
  font-weight: normal;
  color: #ffffff;
  line-height: 47px;
  text-align: center;
  cursor: pointer;
  &:hover {
    margin-top: 27px;
    transition: all 1s ease-in;
  }
}

.wholesale_bg_img {
  width: 414px;
  height: 530px;
  margin-top: 57px;
}

.iYoowe_advantage {
  width: 100%;
  height: 670px;
}

.iYoowe_advantage_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.iYoowe_advantage_content > div {
  width: 390px;
  height: 486px;
  border-radius: 30px;
}

.iYoowe_advantage_content > div > div {
  width: 76px;
  height: 61px;
  margin: 70px auto 0;
  text-align: center;
}

.iYoowe_advantage_content > div > p:nth-of-type(1) {
  width: 360px;
  height: 36px;
  margin: 39px auto 24px;
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  line-height: 36px;
  text-align: center;
}

.iYoowe_advantage_content > div > p:nth-of-type(2) {
  width: 290px;
  height: 233px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: normal;
  color: #404040;
  line-height: 30px;
  text-align: center;
}

.iYoowe_advantage_content > div:hover {
  background: #ffffff;
  box-shadow: 4px 5px 13px 10px rgba(190, 190, 190, 0.31);
}

.categories {
  width: 100%;
  height: 989px;
  background: url(../../assets/img/wholesale/background.png) no-repeat;
  background-size: 100% 989px;
  color: #ffffff;
}

.categories > div:nth-of-type(-n + 2) {
  display: flex;
  justify-content: space-between;
  & > div > img {
    border-radius: 14px;
  }
}

.categories > div:nth-of-type(1) {
  position: relative;
  top: 176px;
  width: 1050px;
  height: 274px;
  margin: 0 auto;
}

.categories > div:nth-of-type(1) > div:nth-of-type(1) {
  width: 509px;
  height: 274px;
}

.categories > div:nth-of-type(1) > div:nth-of-type(n + 2) {
  width: 260px;
  height: 274px;
}

.categories > div:nth-of-type(2) {
  width: 1200px;
  height: 274px;
  margin: 190px auto 0;
}

.categories > div:nth-of-type(2) > div:nth-of-type(1) {
  width: 260px;
  height: 274px;
}

.categories > div:nth-of-type(2) > div:nth-of-type(n + 2) {
  width: 460px;
  height: 274px;
}

.categories > div:nth-of-type(2) > div > img {
  width: 100%;
  height: 274px;
}

.categories > div:nth-of-type(3) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 506px;
  height: 77px;
  margin: 69px auto 0;
  padding: 0 35px;
  background: #37108b;
  border-radius: 39px;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
  &:hover {
    margin-top: 65px;
    transition: all 1s;
  }
  div {
    img {
      width: 24px;
    }
  }
}

.faqs {
  width: 1200px;
  height: 506px;
  margin: 195px auto 0;
}

.faqs img {
  width: 627px;
  height: 506px;
}

.iYoowe_program {
  margin-top: -251px;
  margin-bottom: 191px;
}

.iYoowe_program > div > div {
  position: relative;
  width: 969px;
  height: 84px;
  margin: 0 auto 25px;
  background: url(../../assets/img/wholesale/juxingbeifen21.png) no-repeat 100%;
  p:nth-of-type(n + 2) {
    cursor: pointer;
  }
}

.iYoowe_program > div:nth-of-type(4) > div {
  height: 113px;
  background: url(../../assets/img/wholesale/juxingbeifen25.png);
}

.iYoowe_program > div:nth-of-type(-n + 3) > div > p:nth-of-type(1) {
  width: 562px;
  height: 84px;
  margin-left: 81px;
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  line-height: 84px;
}

.iYoowe_program > div:nth-of-type(4) > div > p:nth-of-type(1) {
  width: 601px;
  height: 56px;
  margin-left: 81px;
  padding-top: 24px;
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  line-height: 33px;
}

.iYoowe_program > div:nth-of-type(5) > div > p:nth-of-type(1) {
  width: 741px;
  height: 47px;
  margin-left: 81px;
  padding-top: 20px;
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  line-height: 47px;
}

.iYoowe_program > div > div > p:nth-of-type(n + 2) {
  position: absolute;
  top: 30px;
  right: 74px;
  width: 23px;
  height: 23px;
}

.iYoowe_program > div:nth-of-type(4) > div > p:nth-of-type(n + 2) {
  top: 45px;
}
.iYoowe_program > div:nth-of-type(5) > div > p:nth-of-type(1) {
  font-size: 23px;
}
.iYoowe_program > div > div > p:nth-of-type(3) {
  display: none;
}

.iYoowe_program > div > p {
  width: 969px;
  padding: 28px 81px;
  margin: -25px auto 0;
  background: #f5f5f5;
  font-size: 18px;
  font-weight: normal;
  color: #333333;
  line-height: 30px;
}
</style>
